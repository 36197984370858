import {variables} from 'theme/variables';
import styled from 'styled-components/macro';
const {
  spacing_sm,
  line_height_xxxl,
  height_xxl,
  raisedValue,
  custom_blue,
  custom_blue_hover,
  font_weight_semibold,
  spacing,
  color_white,
  custom_black,
  spacing_lg,
  custom_silver,
  color_red_400,
  custom_lime,
  custom_navy,
  custom_grey,
  custom_light_grey,
  custom_button_blue,
  public_font_size_md,
  public_font_weight_bold,
  border_radius,
  custom_button_hover,
  public_font_family
} = variables;

export const LinkButton = styled.div`
  text-decoration: none !important;
  display: inline-block;
  position: relative;
  a {
    color: inherit !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  ${props => `${buttonBaseStyles(props)}`}
`

const ButtonTheme = {
  'buttons.icon_button': props => {
    return `
      ${buttonBaseStyles(props, 'icon')}
    `
  },
  'buttons.button': props => {
    return `
      ${buttonBaseStyles(props)}
    `
  },
  'buttons.anchor': props => {
    return `
      text-decoration: none !important;
      display: inline-block;
      ${buttonBaseStyles(props)}
    `
  }
};


function buttonBaseStyles(props, type) {
  return `
    font-family: ${public_font_family};
    font-display: swap;
    *:not(*[class*="icon-"]), *:not(".anchor-child") {
      ${!props.mini ? `
        line-height: ${props.ignoreLineHeight ? 'inherit' : (props.lineHeight || '38px')} !important;
      `: ''}
      ${props.size === "large" ? `
        line-height: ${props.lineHeight || '46px'} !important;
      ` : ''}
    }
    
    ${props.center ? `
      display: block;
      margin: 0 auto;
    ` : ''}

    ${props.spacingLeft ? `
      margin-left: ${spacing}
    ` : ''}

    ${props.spacingRight ? `
      margin-right: ${spacing}
    ` : ''}

    ${props.spacingBottom ? `
      margin-bottom: ${spacing}
    ` : ''}

    ${props.spacingBottomLg ? `
      margin-bottom: ${spacing_lg}
    ` : ''}

    ${props.spacingLeftSm ? `
      margin-left: ${spacing_sm}
    ` : ''}

    ${props.spacingRightSm ? `
      margin-right: ${spacing_sm}
    ` : ''}

    ${props.spacingBottomSm ? `
      margin-bottom: ${spacing_sm}
    ` : ''}

    ${props.spacingTopSm ? `
      margin-top: ${spacing_sm}
    ` : ''}

    ${props.spacingTop ? `
      margin-top: ${spacing}
    ` : ''}

    ${props.alignCenter ? `
      display: flex !important;
      align-items: center !important;
    ` : ''}

    ${props.justifyCenter ? `
      display: flex !important;
      justify-content: center !important;
    ` : ''}

    ${props.justifyEnd ? `
      display: flex !important;
      justify-content: flex-end !important;
    ` : ''}

    ${props.justifyStart ? `
      display: flex !important;
      justify-content: flex-start !important;
    ` : ''}

    ${props.full ? `
      width: 100% !important;
    ` : ''}

    ${props.black ? `
      color: ${custom_black} !important;
    ` : ''}

    ${props.color ? `
      color: ${props.color} !important;
    ` : ''}

    ${props.bold ? `
      font-weight: 500 !important;
    ` : ''}

    ${props.semiBold ? `
      font-weight: ${font_weight_semibold} !important;
    ` : ''}

    ${props.disableBackground ? `
      background-color: transparent !important;
      border-color: transparent !important;
    ` : ''}

    ${props.uppercase ? `
      text-transform: uppercase !important;
    ` : ''}

    ${props.square ? `
      border-radius: 3px !important;
      min-width: 40px;
      ${props.small ? `
        min-width: 32px;
        min-height: 32px;
        max-width: 32px;
        max-height: 32px;
      ` : ''}
    ` : ''}

    ${props.underline ? `
      border-bottom: 2px solid transparent !important;
      text-decoration: none !important;
      padding-bottom: 3px !important;
      ${props.active ? `border-bottom: 2px solid ${custom_blue} !important;` : ''}
    ` : ''}

    ${(props.color || props.blue) && props.link ? `
      transition: all 0.3s ease;
      color: ${props.color || custom_blue} !important;
      * {
        color: ${props.color || custom_blue} !important;
      }
      &:hover {
        opacity: 0.6;
        text-decoration: none !important;
      }
      ${props.semibold ? `
        font-weight: ${font_weight_semibold} !important;
      ` : ''}

      ${props.disabled ? `
        opacity: 1 !important;
        color: ${custom_silver} !important;
        * {
          color: ${custom_silver} !important;
        }
      ` : ''}
    ` : ''}

    ${props.whiteButtonLink ? `
      position: relative;
      background-color: ${props.raised && false ? `${custom_light_grey} !important;` : !props.inactive ? `${color_white} !important;` : `transparent !important;`} 
      border: ${!props.inactive && !props.transparentBorder ? '1px solid #eeeeee !important;' : '1px solid transparent !important;'} 
      overflow: visible !important;
      outline: none;
      box-shadow: none !important;
      &:hover {
        background-color: ${props.active ? `${color_white} !important;` : '#fff !important;'} 
      }
    ` : ''}

    

    ${props.menu ? `
      padding: 0 ${spacing_sm} !important;
      min-width: 0 !important;
    ` : ''}

    ${props.xl ? `
      height: ${line_height_xxxl} !important;
      ${props.square ? `
        min-width: ${line_height_xxxl} !important;
      ` : ''}
    ` : ''}

    ${props.xxl ? `
      height: ${height_xxl} !important;
      min-width: 200px !important;
    ` : ''}


    ${props.raised ? `
      box-shadow: ${typeof props.raised === 'string' ? props.raised : raisedValue} !important;
    ` : ''}

    ${props.primary ? `
      -webkit-font-smoothing: antialiased !important;
      background: ${custom_blue} !important;
      [class*="icon-"] {
        color: ${color_white} !important;
      }
      &:hover {
        background: ${custom_blue_hover} !important
      }
      * {
        font-weight: 500;
      }
      a, a:hover {
        color: #fff !important;
      }
      ${
        !props.buttonState ? `
          &:disabled {
            background: ${custom_light_grey} !important
            color: ${custom_grey};
            border-color: ${custom_light_grey}     
          }
        ` : ''
      }
    ` : ''}

    ${props.primaryOutline ? `
      -webkit-font-smoothing: antialiased !important;
      background: ${color_white} !important;
      border: 1px solid ${custom_blue} !important;
      color: ${custom_blue} !important;
      [class*="icon-"] {
        color: ${custom_blue} !important;
      }
      &:hover {
        background: ${custom_blue_hover} !important;
        color: ${color_white} !important;
        [class*="icon-"] {
          color: ${color_white} !important;
        }        
      }
      &:disabled {
        background: ${custom_light_grey} !important
        color: ${custom_grey};
        border-color: ${custom_light_grey}     
      }
    ` : ''}

    ${props.secondary ? `
      background: #d4dded !important;
      border: 1px solid #d4dded;
      color: ${custom_navy} !important;
      [class*="icon-"] {
        color: ${custom_navy} !important;
      }
      &:hover {
        background: ${custom_blue_hover} !important;
        color: ${color_white} !important;
        [class*="icon-"] {
          color: ${color_white} !important;
        }
      }
    ` : ''}

  

    ${props.marginTop ? `
      margin-top: ${props.marginTop} !important;
    ` : ''}

    

     ${props.publicButton ? `
      padding: 20px 60px;
      border-radius: 8px;
      background-color: ${custom_button_blue};
      box-shadow: ${!props.noShadow ? '0 15px 30px -12px rgba(0,0,0,0.5)' : 'none'};
      color: ${color_white} !important;
      border: 0;
      outline: none;
      font-size: ${public_font_size_md};
      font-weight: ${public_font_weight_bold};
      position: relative;
      &&& div, &&& anchor-child {
        line-height: 20px !important;
      }
      div[class^="flex-"], anchor-child {
        transition: transform 0.3s ease-out;
      }
      
      ${!props.loading ?`
        &&&&:hover:not(:disabled) {
          background-color: ${custom_button_hover};
          div[class^="flex-"], .anchor-child {
            transform: translateX(20px);
          }
        }` : ''
      }
    ` : ''}

    ${(props.animated === false) ? `
      opacity: 0;
      transform: translateY(40px);
    ` : ''}

    ${props.headerButton ? `
      padding: 20px 24px;
      border-radius: ${border_radius};	
      background-color: ${color_white};	
      box-shadow: 0 15px 15px -12px rgba(13,43,83,0.5);
      color: ${custom_button_blue};
      border: 0;
      outline: none;
      font-size: ${public_font_size_md};
      font-weight: ${public_font_weight_bold};
      transition: all 0.3s linear;
      &&& div {
        line-height: 18px !important;
      }
      &&&:hover {
        background-color: ${custom_button_hover};
        color: ${color_white} !important;
      }
      
      margin-left: 16px;
    ` : ''}

    ${props.groupButton ? `
      border: 1px solid #dee5ed;
      color: ${custom_black} !important;
      background: ${color_white} !important;
      box-shadow: none;
      &:hover {
        background: ${custom_blue} !important;
        color: ${color_white} !important;
      }
      ${props.selected ? `
        box-shadow: 0 0px 4px rgb(222, 231, 245) inset;
        background: #f0f6ff !important;
        color: ${custom_blue} !important;
        [class*="icon-"] {
          color: ${custom_blue} !important;
        }
        &:hover {
          background: #f0f6ff !important;
          color: ${custom_blue} !important;
        }
      ` : ''}
    ` : ''}

    ${props.buttonState === 'isSuccess' ? `
      background: ${custom_lime} !important;
      [class*="icon-"] {
        color: ${color_white} !important;
      }
      &:hover {
        background: ${custom_lime} !important
      }
    ` : ''}

    ${props.buttonState === 'isError' ? `
      background: ${color_red_400} !important;
      [class*="icon-"] {
        color: ${color_white} !important;
      }
      &:hover {
        background: ${color_red_400} !important
      }
    ` : ''}


    ${props.border ? `
      border: ${props.border} !important;
    ` : ''}


    ${props.pairButton ? `
     @media (max-width: 576px) {
      margin: 0;
      margin-bottom: 20px;
      min-width: 100%;
      ${props.last ? `
        margin-bottom: 0px;
      ` : ''}
    }
    ` : ''}

    ${props.extraLarge ? `
      font-size: 16px;
    ` : ''}
   
  `;
}

export default ButtonTheme;

