import React from 'react';
import {root} from 'baobab-react/higher-order';
// import {useRoot} from 'baobab-react/hooks';
import tree from './state';

const Root = (props) => {  
  // const Root = useRoot(tree);
  return (
    <div>
      {props.children}
    </div>
  )
}

export default root(tree, Root);