import {variables} from 'theme/variables';
import { isBoolean, isString } from 'lodash'
const {
  spacing,
  media_xxl,
  media_sm
} = variables;

const GridTheme = {
  'grid.col': props => {
    return `
      ${props.columnReverse ? `
        display: flex;
        flex-direction: column-reverse;
      ` : ''}

      ${props.column ? `
        display: flex;
        flex-direction: column;
      ` : ''}

      ${props.alignCenter ? `
        display: flex;
        align-items: center;
      ` : ''}

      ${props.alignEnd ? `
        display: flex;
        align-items: flex-end;
      ` : ''}

      ${props.justifyCenter ? `
        display: flex;
        justify-content: center;
      ` : ''}

      ${props.justifyEnd ? `
        display: flex;
        justify-content: flex-end;
      ` : ''}

      ${props.justifyStart ? `
        display: flex;
        justify-content: flex-start;
      ` : ''}

      ${props.extraPadding ? `
        @media ${isString(props.extraPadding) ? props.extraPadding : media_sm} {
          padding-left: ${spacing}  !important;
          padding-right: ${spacing}  !important;
        }
      ` : ''}

      ${props.extraPaddingRight ? `
        @media ${isString(props.extraPaddingRight) ? props.extraPaddingRight : media_sm} {
          padding-right: ${spacing}  !important;
        }
      ` : ''}

      ${props.extraPaddingLeft ? `
        @media ${isString(props.extraPaddingLeft) ? props.extraPaddingLeft : media_sm} {
          padding-left: ${spacing}  !important;
        }
      ` : ''}

      ${isBoolean(props.xxl) ? `
        @media ${media_xxl} {
          flex-basis: 0 !important;
          flex-grow: 1 !important;
          width: 100% !important;
          max-width: 100% !important;
        }
      ` : ''}
    `;
  },

  'grid.grid': props => {
    return `
      cursor: auto !important;
      ${props.disablePadding ? `
        padding: 0  !important;
      ` : ''}
    `;
  },

  'grid.row': props => {
    return `
      ${props.columnReverse ? `
        display: flex;
        flex-direction: column-reverse;
      ` : ''}

      ${props.column ? `
        display: flex;
        flex-direction: column;
      ` : ''}

      ${props.extraPadding ? `
        margin-left: -${spacing}  !important;
        margin-right: -${spacing}  !important;
      ` : ''}

      ${props.extraPaddingTwo ? `
        @media ${isString(props.extraPaddingTwo) ? props.extraPaddingTwo : media_sm} {
          margin-left: -${spacing}  !important;
          margin-right: -${spacing}  !important;
        }
      ` : ''}
    `;
  },
};

export default GridTheme;
