import UAParser from 'ua-parser-js';
import queryString from 'query-string';
import {canUseDOM} from 'exenv';

import {state as globalState} from './global/state';
import {state as application} from './application/state';
import {state as appSettings} from './appSettings/state';
import {state as preferences} from './preferences/state';
import {state as authentication} from './authentication/state';
import {state as dashboard} from './dashboard/state';
import {state as modals} from './modals/state';
import {state as sidebars} from './sidebars/state';
import {state as sidebarPage} from './sidebarPage/state';

import {state as listingsPage} from './listings/state';
import {state as listingsManagementPage} from './listingsManagement/state';
import {state as contentPage} from './content/state';
import {state as categoriesPage} from './categories/state';
import {state as searchPage} from './search/state';


import {state as tags} from './tags/state';



export default function getInitialState(initialState = {}) {
  const uaParser = new UAParser();
  if (initialState.userAgent) {
    uaParser.setUA(initialState.userAgent);
  }

  let initialQueryParams = {};
  if (canUseDOM) {
    console.log('__INITIAL_STATE__', window.__INITIAL_STATE__);
    initialQueryParams = queryString.parse(window.location.search) || {};
  }


  return {
    cursors: {
      // app based state
      initialQueryParams,
      browser: uaParser.getResult(),
      application: application(initialState),
      global: globalState(initialState),
      appSettings: appSettings(initialState),
      preferences: preferences(initialState),
      tags: tags(initialState),
      // component based state:
      authentication: authentication(initialState),
      dashboard: dashboard(initialState),
      modals: modals(initialState),
      sidebars: sidebars(initialState),
      sidebarPage: sidebarPage(initialState),
      listings: listingsPage(initialState),
      listingsManagement: listingsManagementPage(initialState),
      content: contentPage(initialState),
      categories: categoriesPage(initialState),
      search: searchPage(initialState),
    },
  };
}