import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import {trackClick} from 'utility/analytics';
import {StyledDropdownMenu} from './NavigationDropdownMenu.styles'

class NavigationDropdownMenu extends React.Component {

  constructor(...args) {
    super(...args);
    this.state = {
      showMenu: false
    };
  }

  componentDidUpdate(nextProps, nextState) {
    this.clearHoverTimeouts();
  }

  render() {
    const isShow = this.state.showMenu;
    const forceHide = this.state.forceHide;
    return (
    <StyledDropdownMenu>
      <div
        ref={(r) => this.r = r}
        className={`${this.props.className || ''} ${forceHide ? 'force-hide' : ''} ${isShow ? 'active wrapper-drop-down' : 'wrapper-drop-down'}`}>
        {
          isShow ?
          <div className="drop-down-overlay" onClick={this.props.disableHover ? this.hideMenu.bind(this) : null} style={this.props.overlayStyles}></div> : null
        }
        <div
          onMouseEnter={this.onMouseEnter.bind(this)}
          onMouseLeave={this.onMouseLeave.bind(this)}
        >
            
          <Link style={{cursor: 'pointer', position: 'relative', zIndex: 3}}  to={this.props.link} activeClassName="active-menu-item"  className="item dropdown-main-link" isActive={()=>this.props.isActive} onClick={this.props.disableHover ? this.showMenu.bind(this) : null}>{this.props.customTitle}</Link>
          {
            isShow ?
            <div style={{marginLeft: -5, zIndex: 1}} className={`drop-down`} onClick={this.clickMenu.bind(this)}>
              <div style={{width: '125px'}} className="triangle-wrapper">
                <div className="triangle"/>
              </div>
              {typeof this.props.children === 'function' ? this.props.children({hideMenu: this.hideMenu.bind(this)}) : this.props.children}
            </div> :
            <div style={{height: 0, overflow: 'hidden', position: 'absolute', left: 0, top: 0}}>
              {typeof this.props.children === 'function' ? this.props.children({hideMenu: this.hideMenu.bind(this)}) : this.props.children}
            </div>
          }
        </div>
      </div>
    </StyledDropdownMenu>

    );
  }

  onMouseEnter() {
    if (this.props.disableHover) {
      return;
    }
    this.timeoutId = setTimeout(() => {
      this.clearHoverTimeouts();
      this.showMenu();
    }, 150);
  }

  onMouseLeave() {
    this.clearHoverTimeouts();
    this.hideMenu();
  }

  clearHoverTimeouts() {
    clearTimeout(this.timeoutId);
  }

  showMenu(e) {
    this.boundHideMenu = this.hideMenu.bind(this);
    if (!this.state.showMenu) {
      document.addEventListener('click', this.boundHideMenu);
      this.setState({showMenu: true, forceHide: false});
      if (e) {
        e.nativeEvent.stopImmediatePropagation();
      }
      trackClick(this.props.showEventName || 'viewAccountMenu', this.props.label || this.props.title || '', 'header');
    }
  }

  hideMenu(e) {
    document.removeEventListener('click', this.boundHideMenu);
    this.setState({showMenu: false, forceHide: !!e}, () => { // force hide to hide menu on click events
      if (!!e) {
        this.timeout = setTimeout(() => {
          this.setState({forceHide: false});
        }, 1000);
      }
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  clickMenu(e) {
    let preventStop;
    if (this.props.onClickMenu) {
      preventStop = this.props.onClickMenu();
    }
    if (preventStop) {
      e.nativeEvent.stopImmediatePropagation();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.boundHideMenu);
    clearTimeout(this.timeout);
  }

}


export default NavigationDropdownMenu;