import React from 'react';
import { map, forEach } from 'lodash'
import { Helmet } from 'react-helmet-async';
const DefaultMeta = (props) => {
  return (
    <Helmet>
     <meta
        name="twitter:card"
        value="Magnetic Resonance Technologies: Ensuring Artifact Free Imaging"
      />
      <meta
        name="twitter:image"
        content="https://res.cloudinary.com/hbq7zoknx/image/upload/v1605552775/mrt_e5qfz2.jpg"
      />

      <meta property="og:title" content="Magnetic Resonance Technologies (MRT)" />
      <meta property="og:type" content="article" />
      <meta property="og:url" content="https://mritechnologies.com" />
      <meta
        property="og:image"
        content="https://res.cloudinary.com/hbq7zoknx/image/upload/v1605552775/mrt_e5qfz2.jpg"
      />
      <meta
        property="og:description"
        content="Magnetic Resonance Technologies: Ensuring Artifact Free Imaging"
      />
    </Helmet>
  )
}

export default DefaultMeta;




export const HelmetWrapper = ({metadata}={}) => {
  if (!metadata) {
    return DefaultMeta();
  }
  return (
    <Helmet
      title={metadata.title}>
      {map(metadata.meta, (properties, i) => {
        return (
          <meta key={`meta-${i}`} {...properties} />)
      })}
      {map(metadata.script, ({type, innerHTML}, i) => {
        return (
          <script key={`script-${i}`} type={type}>
            {`${innerHTML}`}
          </script>
        )
      })}
    </Helmet>
  )
}



export const basicHelmetGenerator = (data ={}) => {
    const meta = [];
    const properties = {
      title: data.title || 'Magnetic Resonance Technologies',
      description: data.description || 'MRT is the leading supplier of new and refurbished MRI equipment and parts. MRI sales, service, storage, construction, and mobile MRI.',
      image: data.image || 'https://res.cloudinary.com/hbq7zoknx/image/upload/v1605552775/mrt_e5qfz2.jpg',
    };

    forEach(properties, (v, k) => {
      if (v) {
        meta.push({property: `og:${k}`, content: v});
        meta.push({name: `twitter:${k}`, content: v});
      }
    });
    meta.push({name: 'description', content: properties.description});
    meta.push({name: 'robots', content: 'all'});

    return {title: properties.title, meta};
}


