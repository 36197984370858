import React from 'react';
import {variables} from 'theme/variables';
import Flex from 'styled-flex-component';
import { Grid, Row } from '@zendeskgarden/react-grid';
import { SM, MD } from '@zendeskgarden/react-typography';
import { Link } from 'react-router-dom';

import {
  AppFooterComponent,
  FooterCol,
  Logo,
  FooterDesc
} from './app-footer.styles.js'

const {
  logo_url,
} = variables;

 
const AppFooter = () => {  
  return (
    <AppFooterComponent className="app-footer">
      <Grid fluid>
        <Row>
          <FooterCol size={12} md={4}>
            <Flex alignCenter>
              <Logo src={logo_url} />
            </Flex>

            <FooterDesc style={{maxWidth: '300px'}} paddingTopSm>Ensuring Artifact Free Images </FooterDesc>

            <SM paddingTopSm>© 2020 MRT</SM>

          </FooterCol>

          <FooterCol size={12} md={5}>
           

          </FooterCol>


          <FooterCol size={12} md={3}>
            <MD paddingTopMd>
              <Link to="/services">
                Services
              </Link>              
            </MD>

            <MD paddingTopMd>
              <Link to="/why-mrt">
                Why MRT
              </Link>              
            </MD>

              <MD paddingTopMd>
              <Link to="/contact">
                Contact Us
              </Link>
            </MD>

          </FooterCol>

        </Row>
      </Grid>
    </AppFooterComponent>
  )
}



export default AppFooter

