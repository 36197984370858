import React, { useState, useEffect } from 'react';
import {useBranch} from 'baobab-react/hooks';
import {default as StyledModalComponent} from "forks/customModal";
import ConfirmEmailModal from './modals/ConfirmEmailModal/ConfirmEmailModal';
import ContactUsModal from './modals/ContactUsModal/ContactUsModal';
import InquireFormModal from './modals/InquireFormModal/InquireFormModal';
import EditListingModal from './modals/EditListingModal/EditListingModal';

import {closeModal} from 'state/modals/actions';

const StyledModal = StyledModalComponent.styled`
  min-width: ${props => props.width || '200px'};
  min-height: ${props => props.height || '20rem'};
  height: auto;
  width: auto
  display: flex;
  align-items: flex-start;
  border-radius: 0.28571429rem;
  justify-content: center;
  background-color: #fff;
  border-radius: 4px;
  opacity: ${props => props.opacity};
  transform: ${props => props.opacity ? 'translate3d(0, 0, 0)' : 'translate3d(0, -20px, 0)'};
  transition: all ease 600ms 20ms;
  margin: 5% auto 0;
`
const innerModalStyles = {          
  'position': 'relative',
  'top': 'auto',
  'bottom': 'auto',
  'left': 'auto',
  'right': 'auto',
  'boxShadow': 'none',
  'background': 'none',
}
 
const GlobalModals = (props) => {
  const [type, setType] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const {modalsCursor} = useBranch({
    modalsCursor: ['modals']
  });
  const modals = modalsCursor || {};

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1)
    }, 100);
  }

  function beforeClose() {
    return new Promise(resolve => {
      setOpacity(0)
      setTimeout(resolve, 200);
    });
  }

  useEffect(() => {
    if (type && !modals.modalType) {
      setTimeout(() => {
        setType(null)
      }, 201)
    } else {
      setType(modals.modalType)
    }
  }, [modals.modalType])

  const activeType = type || modals.modalType;

  return (
    <StyledModal
      isOpen={modals.modalOpen}
      onBackgroundClick={closeModal}
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      onEscapeKeydown={closeModal}
      opacity={opacity}
      backgroundProps={{ opacity: opacity }}>
        {
          activeType === 'editListingModal'?
          <EditListingModal {...modals.modalData} styles={innerModalStyles} /> : 
          activeType === 'contactUsModal'?
          <ContactUsModal {...modals.modalData} styles={innerModalStyles} /> : 
          activeType === 'confirmEmailModal'?
          <ConfirmEmailModal {...modals.modalData} styles={innerModalStyles} /> : 
          activeType === 'inquireFormModal'?
          <InquireFormModal {...modals.modalData} styles={innerModalStyles} />
          : null
        }
    </StyledModal>
  )
}


export default GlobalModals;

