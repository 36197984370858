import React from 'react';
import { trackClick } from 'utility/analytics';
import {closeModal} from 'state/modals/actions';
import Icon from 'components/Icon/Icon';
import { MD, XL } from '@zendeskgarden/react-typography';
import styled from 'styled-components/macro';
import Flex from 'styled-flex-component';
import PageViewWrapper from 'higherOrder/PageViewWrapper';
import ContactUsFormTwo from 'components/BasicForms/ContactUsFormTwo';



export const Wrapper = styled.section`
  width: 500px;
  max-width: 90%;
  .wrapper {
    width: 100%;
    padding: 30px !important;
     @media(max-width:600px) {
      padding: 20px !important;
    }
  }
  form {
    width: 100%;
    button {
      margin-bottom: 0 !important;
      width: 100%;
    }
  }
  .email-link {
    padding: 10px;
    background: #eef0f3;
    text-align: center;
    width: 100%;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    a {
      color: #5a5a5a !important;
    }
  }
  .icon-close {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  
`

const ContactUsModal = (props) => {
  return (
    <Wrapper style={{margin: '0 auto', maxWidth: '800px', ...(props.styles || {margin: '0 auto'})}}>
      <Icon onClick={closeModal} buttonStyle icon="icon-close" fontSize={'10px'}/>
      <Flex className="wrapper" column alignCenter justifyBetween>
        <XL center bold>Contact Magnetic Resonance Technologies</XL>
        <MD paddingTopSm slate center light>Industry Leaders In Acquiring and Installing Medical Imaging Equipment.</MD>
        <ContactUsFormTwo />
      </Flex>
      <Flex style={{padding: '10px 35px', background: 'rgb(238 240 243)'}}>
        <MD style={{textAlign: 'left', padding: 0}} className="email-link" tag="p"><a onClick={() => trackClick('callUs', '440-942-2922')} href="tel:440-942-2922">440.942.2922</a></MD>
        <MD style={{textAlign: 'right', padding: 0}} className="email-link" tag="p"><a onClick={() => trackClick('emailUs', 'sales@mritechnologies.com')} href="mailto:sales@mritechnologies.com">sales@mritechnologies.com</a></MD>
      </Flex>
      
    </Wrapper>
  )
}

export default PageViewWrapper(ContactUsModal, {pageType: 'modal', pageName: 'ContactUsModal', ignoreScrollTop: true});