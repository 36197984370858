import tree from 'state';
var browserCursor = tree.select(['browser']);
let isInternetExplore11;
let isEdge;

export default function isIe11(num) {
  if (isInternetExplore11) {
    return isInternetExplore11;
  } else {
    const browserVal = browserCursor.get();
    isInternetExplore11 = browserVal && browserVal.browser && browserVal.browser.name === 'IE' && Number(browserVal.browser.version) < 12
    return isInternetExplore11
  }
}

export function isEdgeBrowserOrIe() {
  if (isEdge) {
    return isEdge;
  } else {
    const browserVal = browserCursor.get();
    isEdge = browserVal && browserVal.browser && (browserVal.browser.name === 'IE' || browserVal.browser.name === 'Edge')
    return isEdge
  }
}