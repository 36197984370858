import React, {useCallback} from 'react';
import Promise from 'bluebird';
import {useDropzone} from 'react-dropzone';
import { SM } from '@zendeskgarden/react-typography';

export default function Uploader(props) {
  const onDrop = useCallback(async (acceptedFiles) => {
    const url = "https://api.cloudinary.com/v1_1/hbq7zoknx/upload";
    const files = acceptedFiles;
    const formData = new FormData();
    const images = await Promise.map(files, async (f) => {
      let file = f;
      formData.append("file", file);
      formData.append("upload_preset", "rp7pwtuo");
      return await fetch(url, {
        method: "POST",
        body: formData
      }).then((response) => {
        return response.json();
      }).then((data) => {
        return data.secure_url
      });
    })
    
    props.setFieldValue(props.type || 'image', images[0])
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <SM link paddingTopSm>Upload New File</SM> :
          <SM link paddingTopSm>Upload New File</SM>
      }
    </div>
  )
}