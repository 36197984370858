import React, { useEffect } from 'react';
import Routes from './routes';
import { renderRoutes } from 'react-router-config';
import { Switch, withRouter } from 'react-router-dom';
import ModalWrappers from 'components/ModalWrappers/ModalWrappers';
import AppHeaderSidebar from 'components/AppHeader/AppHeaderSidebar';
import PublicHeader from 'components/AppHeader/PublicHeader';
import PublicFooter from 'components/AppFooter/PublicFooter';
import AppFooter from 'components/AppFooter/AppFooter';
import {get, intersection} from 'lodash'
import {AppContainer} from './App.styles.js';
import {ConfirmEmailWrapper} from 'routes/ConfirmEmail/ConfirmEmail';
import NotificationBanner from 'components/NotificationBanner/NotificationBanner';
import isIe11 from 'utility/isIe11';
import DefaultMeta from 'utility/DefaultMeta';
import GlobalAppApiRequests from 'utility/GlobalAppApiRequests';
import loadGlobalScrips from 'utility/scriptInjector';

import './app.css';

const hiddenHeaderRoutes = ['page-confirm-email', 'page-sign-in', 'page-login', 'page-recover-password', 'page-recover-password-recover', 'page-register', 'page-login-template', 'page-account-created', 'page-reset']
const hiddenFooterRoutes = [...hiddenHeaderRoutes]
const sidebarRoutes = ['page-dashboard', 'page-settings']
const publicRoutes = ["page-magnetic-shielding.html", "page-rf-shielding.html", "page-service-repairs.html", "page-services.html", 'page-inquire','page-index.html', 'page-index','page-index.html','equipment-storage.html','page-equipment.html','page-equipment', 'page-about.html','page-about','page-disclaimer', 'page-listings',"page-tags", "page-technical-content", "page-equipment-news", "page-industry-news", "page-blog", 'page-our-model', 'page-about-us', 'page-s', 'page-c', 'page-l', 'page-p', 'page-beta', 'page-item-wishlist', 'page-landing', 'page-services', 'page-why-mrt', 'page-company', 'page-contact', 'page-search-results', 'page-sitemap', 'page-blog', 'page-blog-post']


const App = (props) => {
  useEffect(() => {
    loadGlobalScrips();
  }, []);
  
  const pageClasses = generatePageClass(props);
  const hiddenHeader = intersection(hiddenHeaderRoutes, pageClasses.split(' ')).length;
  const hiddenFooter = intersection(hiddenFooterRoutes, pageClasses.split(' ')).length;
  const sidebarHeader = intersection(sidebarRoutes, pageClasses.split(' ')).length;
  const isPublicRoute = intersection(publicRoutes, pageClasses.split(' ')).length;
  const isConfirmEmailRoute = intersection(['page-confirm-email'], pageClasses.split(' ')).length;
  
  let path = props.location.pathname;
  const modalString = get(path.match(/(\/m\/)|(\/m$)/), '[0]');
  const overflowHidden = !!modalString;
  return (
    <div className={`App ${pageClasses} ${overflowHidden ? 'main-overflow-hidden' : ''} ${isIe11() ? 'ie-eleven' : ''}`}>
      <GlobalAppApiRequests />
      <DefaultMeta />
      <NotificationBanner />
      {
        get(props, 'location.query.confirmation') && !isConfirmEmailRoute ?
        <ConfirmEmailWrapper banner={true} {...props} confirmation={get(props, 'location.query.confirmation')} /> : null
      } 
      {
        !hiddenHeader && sidebarHeader ?
        <AppHeaderSidebar /> :
        !hiddenHeader && (isPublicRoute) ?
        <PublicHeader /> :
        !hiddenHeader ?
        <div/> : null 
        }
        
        <div className="transition-container">
          {
            isPublicRoute ?
            <AppContainer className="public-app-container" hiddenFooter={hiddenFooter} hiddenHeader={hiddenHeader} publicHeader={isPublicRoute}>
              <AppRoutes appRoutes={Routes} location={props.location} modalString={modalString} />
            </AppContainer> : 
            <AppContainer className="dashboard-app-container" hiddenFooter={hiddenFooter} hiddenHeader={hiddenHeader} publicHeader={isPublicRoute}>
              <AppRoutes appRoutes={Routes} location={props.location} modalString={modalString} />
            </AppContainer>
          }

          {
            !hiddenFooter && (isPublicRoute) ?
            <PublicFooter /> :
            !hiddenFooter ?  
            <AppFooter/> : null
          }
          </div>
       
      <ModalWrappers />
    </div>
  )
}


const AppRoutesWrapper = ({ appRoutes, location }) => {
  return (
    <Switch location={location}>
      {renderRoutes(appRoutes)}
    </Switch>
  )
}

const AppRoutes = React.memo(AppRoutesWrapper, (pp, np) => {
  return np.modalString
});


function generatePageClass(props) {
  const path = get(props, 'location.pathname', '')
  return (path === '/') ? 'page-landing' : path.replace(/\//g, ' page-').toLowerCase();
}

export default withRouter(App);
