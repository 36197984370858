import styled from 'styled-components/macro';
import { variables } from 'theme/variables';

export const StyledNotificationBanner = styled('div')`
  
`

export const IeBanner = styled('div')`
  background: #e5e8ef;
  padding: 10px 20px;
  height: 100px;
  min-width: 1020px;
  vertical-align: middle;
  display: block;
`

export const SupportWrapper = styled('div')`
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  * {
    text-align: center;
  }
  img {
    display: block;
    width: 50px;
    height: 50px;
    margin: 0 20px;
  }
`
