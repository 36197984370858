import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
const {
  custom_dark_blue,
  color_white,
  custom_button_blue,
  custom_pale_blue,
  public_font_size_sm,
  custom_blue,
  public_app_max_width,
  media_lg,
  public_font_family,
  media_md
} = variables;

export const PublicHeaderComponent = styled.header`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 85px;
  background: ${custom_dark_blue};
  font-family: ${public_font_family};
  transition: all 0.3s ease;
  @media ${media_md} {
    padding: 0 20px;
  }
  .header-inner {
    max-width: ${public_app_max_width};
    margin: 0 auto;
    height: 100%;
    position: relative;
    z-index: 3;
    padding: 0px 12px;
  }
  .header-grid{
    z-index: 2;
    position: relative;
    padding: 0;
    @media ${media_md} {
      padding: 0 15px;
    }
  }

  .header-grid, .header-row {
    height: 100%;
  }


  ${props => true ? `
    background: ${color_white};
    box-shadow: ${!props.whiteMenu ? 'none' : '0 20px 20px -12px rgba(20,29,47,0.08)'};
    opacity: 1;
    height: 60px !important;
    @media ${media_md} {
      height: 75px !important;
    }
    .header-grid{
      background: #fff;
    }
    .menu {
      .inner > .nav-link-a, .inner > .icon-nav-link, .inner .item {
        color: ${custom_pale_blue} !important;

        &:hover {
          color: ${custom_button_blue} !important;
        }
        &.active {
          color: ${custom_blue} !important;
        }
      }

      .inner > .nav-link-a.active-menu-item, .inner > .icon-nav-link .active-menu-item, .wrapper-drop-down .active-menu-item  {
        color: ${custom_button_blue} !important;
        &.active {
          color: ${custom_blue} !important;
        }
      }
    }
    .header-button {
      background-color: ${custom_button_blue};
      color: ${color_white};
      transform: scale(0.8);
    }
  `: ``}

  ${props => props.headerHidden ? `
    backface-visibility: hidden;
    pointer-events: none;
    opacity: 0;
    .header-inner {
      transition: all 0.3s 0s ease;
      transform: translate3d(0, -20px, 0);
    }
  ` : ''}

  .header-button {
    min-width: 0;
    transition: transform 0.1s linear;
  }

  .primary-menu-col, .secondary-menu-col {
    display: none;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .logo-col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 500px) {
      min-width: 270px;
    }
  }

  @media ${media_lg} {
    height: 84px;
    .secondary-menu-col {
      display: flex;
      padding-right: 60px;
    }
  }


  @media ${media_lg} {
    .secondary-menu-col {
      padding-right: 10px;
    }
    .primary-menu-col {
      display: flex;
    }
    .logo-col {
      align-items: center;
    }
  }
`;

export const Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuItem = styled.li`
  color: ${color_white};
  position: relative;
  flex-shrink: 0;
  a, span {
    padding: 16px 16px;
    font-weight: 500;
    font-size: ${public_font_size_sm};
    color: ${custom_dark_blue};
    transition: color 0.2s linear;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    [class^="icon-"]:before {
      cursor: pointer;
    }
    &:hover, &:focus {
      color: ${custom_button_blue} !important;
      text-decoration: none;
    }
  }
`;

export const HeaderRight = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 100%;
  Button {
    background-color: ${color_white};
    color: ${custom_button_blue};
  }
`;


export const Logo = styled('div')`
  max-width: 325px;
  opacity: 1;  
  max-height: 70px;
  img {
    width: auto;
    height: 42px; 
    margin-top: 9px;
    @media ${media_md} {
      height: auto;
      width: 100%;
      margin-top: 0;
    }
  }
`;


export const MobileMenuTrigger = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 24px;
  width: 24px;
  height: 20px;
  cursor: pointer;
  display: block;
  z-index: 4;
  &:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: -20px;
    left: -20px;
    bottom: -20px;
    right: -20px;
    margin: auto;
    background: ${custom_button_blue};
    transform: scale(0);
    transition: transform 0.3s cubic-bezier(0.220, 0.265, 0.085, 0.980);
    will-change: auto;
  }
  span {
    background: ${custom_button_blue};
    width: 100%;
    height: 2px;
    position: absolute;
    border-radius: 2px;
    right: 0;
    transition: all 0.2s linear;
    z-index: 2;
    opacity: 1;
    &:nth-child(1) {
      top: 0;
      transform-origin: 0% 50%;
    }
    &:nth-child(2) {
      top: 0;
      bottom: 0;
      margin: auto;
      width: calc(100% - 3px);
      z-index: 1;
    }
    &:nth-child(3) {
      bottom: 0;
      width: calc(100% - 6px);
      transform-origin: 100% 50%;
    }
  }

  ${props => props.active ? `
    &:before {
      transform: scale(1);
    }
    span {
      &:nth-child(1) {
        width: 14px;
        background: ${color_white};
        transform: translateX(-3px) translateY(14px) rotate(-45deg);
      }
      &:nth-child(2) {
        width: 0px;
        
      }
      &:nth-child(3) {
        width: 14px;
        background: ${color_white};
        transform: translateX(-7px) translateY(-4px) rotate(45deg);
        
      }
    }
  ` : ''}

    @media ${media_lg} {
      display: none;
    }
`;



