import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import 'core-js/features/string/starts-with';
import 'core-js/features/string/ends-with';
import 'core-js/features/instance/repeat';
import 'core-js/features/string/repeat';
import 'core-js/features/instance/map';
import 'core-js/features/object/values';
import 'core-js/features/object/entries';
import 'core-js/features/string/match';
import 'core-js/features/array/fill';
import 'core-js/features/array/find-index';
import 'core-js/features/string/repeat';
import 'core-js/features/set';

import 'fast-text-encoding/text';

import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { Router } from 'react-router-dom';
import { canUseDOM } from 'exenv';
import * as Sentry from '@sentry/browser';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import history from './historyContainer';
import App from './App';
import ErrorBoundry from 'components/ErrorBoundry/ErrorBoundry';
import ThemeProviderOverrides from 'theme/ThemeProviderOverrides/index';
// import { ENVIRONMENT, SENTRY_URL } from './constants';
import { HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from '@apollo/client';
import { client } from './utility/apollo.js';

import 'theme/variables';
import './index.css';
import './theme/global-build.css'

// import Store from 'store/store';
import { identifyUser } from 'state/authentication/effects';
import Root from './Root';

try {
  Sentry.init({
   dsn: "https://f9df697a8c6e40988dc04c8eb731f9eb@o457001.ingest.sentry.io/5450604"
  });
} catch(err) {
  console.log(err, 'err')
}

const Provider = Root;
const AppBundle = (
  
    <Router history={history}>
      <ThemeProvider theme={ThemeProviderOverrides}>
        <ApolloProvider client={client}>
          <Provider>
            <ErrorBoundry>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </ErrorBoundry>
          </Provider>
        </ApolloProvider>
      </ThemeProvider>
    </Router>
);


function render() {
  let session = refreshSession()
  window.onload = async () => {
    session = await session;
    setUser(session);
    Loadable.preloadReady().then(() => {
      ReactDOM.hydrate(
        AppBundle,
        document.getElementById('root')
      );
    });
  };
}


async function refreshSession() {
  return new Promise(async (resolve) => {
    let session;

    try {
      if (localStorage.getItem('email')) {
        session = {
          user_id: localStorage.getItem('email'),
          first_name: localStorage.getItem('phone'),
          last_name: localStorage.getItem('first_name'),
          email: localStorage.getItem('last_name'),
          phone: localStorage.getItem('user_id')
        }
        identifyUser(session);
      }
    } catch(err) {}


    resolve(session)
    // if (wasServerRendered()) {
    //   session = tree.get(['authentication', 'session']);
    //   resolve(session)
    // } else {
    //   try {
    //     const {csrftoken} = await getToken();
    //     tree.set(['authentication', 'csrftoken'], csrftoken);
    //     session = await checkSession();
    //     if (get(session, 'success')) {
    //       tree.set(['authentication', 'session'], session);
    //     }
    //     resolve(session)
    //   } catch(err) {
    //     // IF ERROR REDIRECT (TODO)
    //     resolve() // REOLSOVE FOR NOW
    //     console.log(err)
    //   }
      
    // }
  })
}

function wasServerRendered() {
  return !canUseDOM || (canUseDOM && window.__INITIAL_STATE__)
}

function setUser(session = {}) {
  session = session || {}
  identifyUser(session);
}


render()
// registerServiceWorker();

