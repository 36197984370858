import React from 'react';
import { trackClick } from 'utility/analytics';
import { Row, Col } from '@zendeskgarden/react-grid';
import { Link } from 'react-router-dom';
import {
  PublicFooterComponent,
  FooterMenu,
  Copyright,
  FooterGrid
} from './PublicFooter.styles.js'

const PublicFooter = () => {
  return (
    <PublicFooterComponent>
      <FooterGrid>
       
        <Row>
      
          <Col md={3} style={{width: 'auto', padding: '0 40px'}}>
          <FooterMenu className="footer-menu">
              <div className="title">
                About
              </div>
              <ul>
                <li><Link to="/why-mrt">Why Us</Link></li>
                <li><Link to="/company">Our Company</Link></li>
                <li><Link to="/disclaimer">Terms & Disclaimer</Link></li>
              </ul>
            </FooterMenu>
          </Col>
          <Col md={3} style={{width: 'auto', padding: '0 40px 0 20px'}}>
            <FooterMenu className="footer-menu">
              <div className="title">
                Actions
              </div>
              <ul>
                <li><Link to="/contact">Inquire On A Service</Link></li>
                <li><Link to="/services/equipment-procurement">Equipment Procurement</Link></li>
                <li><Link to="/c/equipment">Find A Machine</Link></li>

              </ul>
            </FooterMenu>
          </Col>
          <Col md={3} style={{width: 'auto', padding: '0 40px'}}>
          <FooterMenu className="footer-menu">
              <div className="title">
                Common Services
              </div>
              <ul>
                <li><Link to="/services/rf-shielding">RF Shielding</Link></li>
                <li><Link to="/services/pre-install-planning">Pre Install</Link></li>
                <li><Link to="/services/site-preperation">Site Planning</Link></li>
                <li><Link to="/services">All Services</Link></li>
              </ul>
            </FooterMenu>
          </Col>

          <Col md={3} style={{width: 'auto', padding: '0 40px'}}>
          <FooterMenu className="footer-menu">
              <div className="title">
                Contact
              </div>
              <ul>
                <li><Link to="/contact">Contact Us</Link></li>
                <li><a onClick={() => trackClick('emailUs', 'sales@mritechnologies.com')} href="mailto:sales@mritechnologies.com">sales@mritechnologies.com</a></li>
                <li><a onClick={() => trackClick('callUs', '440-942-2922')} href="tel:440-942-2922">(440) 942-2922</a></li>

              </ul>
            </FooterMenu>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            {/*<Copyright style={{marginTop: '10px'}}>4261 Hamann Parkway Willoughby, Ohio 44094</Copyright>*/}
            <Copyright>© 2020 MRI Technologies <br className="mobile-br"/> All Rights Reserved.</Copyright>
          </Col>
        </Row>
      </FooterGrid>
      
    </PublicFooterComponent>
  )
}



export default PublicFooter;
