import React, {Component} from 'react';
import menuAim from 'higherOrder/menuAim';
import {NavLink as Link} from 'react-router-dom';
import NavigationDropdownMenu from './NavigationDropdownMenu';
import {get, find, map} from 'lodash';
import {LargeMenu} from './ProductsDropdownMenu.styles';

const listOfCategories = [
  {
    name: "Planning",
    routes: [
      {
        path: ["/services/equipment-procurement"],
        name: "Equipment Procurement",
      },
      {
        path: ["/services/pre-install-planning"],
        name: "Pre Install Planning",
      },
      {
        path: ["/services/site-preperation"],
        name: "Site Prep",
      },
      // {
      //   path: ["/services/magnetic-shielding"],
      //   name: "Magnetic Shielding",
      // },
    ]
  },
  
  {
    name: "Ongoing Services",
    routes: [
      // {
      //   path: ["/services/testing-and-repairs"],
      //   name: "Testing & Repairs",
      // },
      {
        path: ["/services/rf-shielding"],
        name: "RF Shielding",
      },
      {
        path: "/services/install-deinstall-rigging",
        name: "Install / Deinstall",
      },
      {
        path: ["/services/ongoing-services"],
        name: "On Going Services",
      }
    ]
  }
]

class ProductsDropdownMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.initMenuAim({
      menuRef: this.menuRef,
      delay: 300,
      tolerance: 75
    });
  }

  render() {
    return (
      <LargeMenu>
        <NavigationDropdownMenu
          link={`/services`}
          customTitle="Services"
          className={`dropdown-menu services-dropdown-menu`}
          onClickMenu={this.onClickMenu.bind(this)}
          onClose={this.onClose.bind(this)}
          {...this.props}>
          {
            (p) => {
              return (
                <div
                  className="categories"
                  onMouseLeave={this.props.handleMouseLeaveMenu}
                  ref={(r) => this.menuRef = r}>
                  {
                    map(listOfCategories, (group) => {
                      return (
                        <div className="group" key={group.name}>
                          <div className="column-group-name">{group.name}</div>
                          {
                            map(group.routes, (cat, i) => {
                              return (
                                <div className="link-wrapper" key={i}>
                                  <Link
                                    key={i}
                                    to={`${cat.path}`}
                                    className={`dropdown-link ${cat.name === get(this.state, 'selectedCategory.name') ? 'active' : ''}`}
                                    onClick={(e) => {
                                      p.hideMenu(e);
                                      this.onClickCategory.bind(this, cat.name)
                                    }}>
                                      <h5>
                                        {cat.name}
                                      </h5>
                                  </Link>
                                </div>
                              )
                            })
                          }
                        </div>
                      );
                    })
                  }
                </div>
              )
            }
          }
      </NavigationDropdownMenu>
      </LargeMenu>
    );
  }


  onClickMenu() {
    return !this.state.selectedCategory;
  }

  onClose() {
   this.setState({selectedCategory: false});
  }

  onClickCategory(name, e) {
    const category = find(listOfCategories, {name});
    this.setState({
      selectedCategory: category
    });
    this.props.handleMouseLeaveMenu({}, e);
  }


}

export default menuAim(ProductsDropdownMenu);









// {
//     name: "MRI Shielding",
//     routes: [
//       {
//         path: ["/services/rf-shielding"],
//         name: "RF Shielding",
//       },
//       {
//         path: ["/services/magnetic-shielding"],
//         name: "Magnetic Shielding",
//       },
//     ]
//   },
//   {
//     name: "MRI Install",
//     routes: [
//        {
//           path: "/services/install-deinstall",
//           name: "Install / Deinstall",
//         },
//         {
//           path: ["/services/rigging-deliver"],
//           name: "Deliver",
//         },
//     ]
//   },

//   {
//     name: "Maintenance",
//     routes: [
//        {
//         path: ["/services/maintenance"],
//         name: "Maintenance",
//       },
//       {
//         path: ["/services/testing-and-repairs"],
//         name: "Testing & Repairs",
//       },
//     ]
//   },

//   {
//     name: "Storage",
//     routes: [
//       {
//         path: ["/services/storage"],
//         name: "Storage",
//       },
//     ]
//   },

//   {
//     name: "Procurement",
//     routes: [
//       {
//         path: ["/services/procurement/mri"],
//         name: "MRI Procurement",
//       },
//       {
//         path: ["/services/procurement/ct"],
//         name: "CT Procurement",
//       },
//     ]
//   }