import React from 'react';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import styled from 'styled-components/macro';
import {variables} from './variables';
const {
  app_page_padding,
  media_sm,
  media_md,
  app_page_padding_medium,
  app_page_padding_small,
  sidebar_menu_width,
  sidebar_menu_minimized,
  app_header_height,
  app_mobile_header_height,
  app_footer_height,
  spacing,
  spacing_lg,
  custom_border_grey,
  public_app_max_width,
  sidebar_padding,
  media_lg
} = variables

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.background || undefined}; 
  padding: ${props => props.padding || props.mobilePadding || app_page_padding_small};
  @media ${media_sm} {
   padding: ${props => props.padding || props.tabletPadding || app_page_padding_medium};
  }
  @media ${media_md} {
    padding: ${props => props.padding || app_page_padding};
  }
`;


export const PageCard = styled.div`
  border-radius: 6px;
  border: 1px solid ${custom_border_grey};
  background: ${props => props.background || '#fff'}; 
  padding: ${props => props.padding || props.small ? `${spacing} ${spacing_lg}` : spacing_lg};
  margin-bottom: ${props => props.marginBottom || spacing_lg};
  transition: all 0.3s ease;
  ${props => props.withHeader ? `
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  ` : ''}
  ${props => props.withFooter ? `
    padding-bottom: 0px;
  ` : ''}
  ${props => props.hover ? `
    &:hover {
      box-shadow: 0px 4px 5px rgba(66,100,251,0.1);
      cursor: pointer;
    }
  ` : ''}
`;

export const PageCardHeader = styled.div`
  padding: ${props => props.padding || props.small ? `${spacing} ${spacing_lg}` : spacing_lg};
  border-bottom: 1px solid ${custom_border_grey};
  ${props => props.withButton ? `
    padding: ${props.padding || props.small ? `10px ${spacing_lg}` : spacing_lg};
  ` : ''}
`;

export const PageCardFooter = styled.div`
  padding: ${props => props.padding || props.small ? `10px ${spacing_lg}` : spacing_lg};
  border-top: 1px solid ${custom_border_grey};
  ${props => props.withButton ? `
    padding: ${props.padding || props.small ? `10px ${spacing_lg}` : spacing_lg};
  ` : ''}
`;

export const PageCardBody = styled.div`
  padding: ${props => props.padding ? props.padding : props.small ? `${spacing} ${spacing_lg}` : spacing_lg};
  padding-bottom: ${props => props.paddingBottom ? (props.padding || props.small ? `${spacing} ${spacing_lg}` : spacing_lg) : '0px'};
`;


export const SidebarPage = styled.div`
  padding: 10px;
  padding-top: 0;
  @media ${media_md} {
    padding-left: ${sidebar_menu_width};
    padding-bottom: ${app_page_padding};
    padding-right: 20px;
    padding-top: 0;
    transition: all 0.2s ease;
    ${props => props.sidebarMinimized ? `
      padding-left: ${sidebar_menu_minimized};
    ` : ''}
  }
`;

export const SidebarPageBody = styled.div`
  padding: ${app_page_padding_small};
  position: relative;
  background-color: #fff;
  @media ${media_md} {
    padding: ${app_page_padding_small};
  }
  &.container-dashboard, &.container-assessment, &.container-order-initiation, &.container-order-management, &.container-information, &.container-items, &.container-activity, &.container-documents  {
    padding: 0 !important;
    background: transparent !important;
  }
`;


export const SidebarPageMenu = styled.section`
  padding-top: 0;
  top: ${app_mobile_header_height};
  white-space: nowrap;
  overflow: auto;
  height: 50px;
  padding: 0;
  width: 100%;
  margin-bottom: 5px;
  padding-top: 5px;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  & {
    ::-webkit-scrollbar {display: none; }
  }
  @media ${media_md} {
    padding-top: 0px;
    display: flex;
    white-space: unset;
    display: block;
    height: auto;
    position: fixed;
    left: 0;
    margin-bottom: 0;
    width: ${sidebar_menu_width};
    overflow: scroll;
    min-height: calc(100% - ${app_header_height});
    top: ${app_header_height};
    padding-bottom: ${app_footer_height};
    transition: all 0.2s ease;
    button {
      min-width: 100% !important;
    }
    ${props => props.sidebarMinimized ? `
      width: ${sidebar_menu_minimized};
    ` : ''}
  }
`

export const SidebarSecondaryHeader = styled.div`
  width: 100vw;
  margin-left: -${app_page_padding_small};
  display: flex;
  align-items: center;  
  margin-bottom: ${sidebar_padding};
  background: #f8fbff;
  height: 60px;
  padding-right: 10px;
  padding-left: 10px;
  @media ${media_md} {
    padding-left: 0px;
    margin-left: 0;
    width: calc(100% + 20px);
    padding-right: 20px;
  }
`





export const StyledLandingSection = styled('div')`
  background-color: ${(props) => props.background || '#fff'};
  position: relative;
  ${props => props.skewed ? `
    margin-top: 100px;
    &:before {
      content: "";
      background: #f3f5f7;
      position: absolute;
      bottom: 0;
      top: -69px;
      transform: rotate(-3.4deg);
      height: 100%;
      left: -51px;
      right: -51px;
    }
  ` : ''}
`;

export const LandingSectionInner = styled('div')`
  max-width: ${public_app_max_width};
  margin: 0 auto;
  padding: ${(props) => '50px' || '0'} 15px 50px;
  @media ${media_sm} {
    padding: ${(props) => '50px' || '0'} 16px 50px;
  }
  @media (min-width: 1430px) {
    padding: ${(props) => '50px' || '0'} 20px 50px;
  }
`;


export const LandingSection = (props) => {
  return (
    <StyledLandingSection skewed={props.skewed} background={props.background}>
      <LandingSectionInner background={props.background} style={props.style || {}}>
        <Grid>
          <Row>
            <Col style={props.colStyle || {}}>
              {props.children}
            </Col>
          </Row>
        </Grid>
      </LandingSectionInner>
    </StyledLandingSection>
  )
}
