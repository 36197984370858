import React, {useState} from 'react';
import { TabList, Tab } from '@zendeskgarden/react-tabs';
import { trackClick } from 'utility/analytics';
import { Button } from 'theme/Button';
import Icon from 'components/Icon/Icon';
import {useBranch} from 'baobab-react/hooks';
import {SidebarFilterTabs, SidebarFilterHeader, SidebarFilterBody, SidebarContentWrapper, SidebarFooterWrapper} from '../../GlobalSideBar.styles.js';
import Flex from 'styled-flex-component';
import { IconButton } from '@zendeskgarden/react-buttons';
import { MD } from '@zendeskgarden/react-typography';
import { get } from 'lodash';
import {closeSidebar} from 'state/sidebars/actions';
import {variables} from 'theme/variables';

const {
  spacing,
} = variables;



const AddCompanySidebar = (props) => {
  const [tab, setActiveTab] = useState('details');
  const {activeCompany, activeCompanyDetails, companyDetails} = useBranch({
    activeCompany: ['companies', 'activeCompany', 'companyFormData'],
    companyDetails: ['companies', 'activeCompany', 'companyDetails', 'result'],
    activeCompanyDetails: ['companies', 'activeCompany', 'companyDetails']
  });



  return (
    <SidebarContentWrapper>
      <SidebarFilterHeader padding={`${spacing}`}>
        <Flex full justifyBetween alignCenter>
          <Flex alignCenter>
            <Icon icon="icon-profile" fontSize="18px" color={'#fff'} />
            <MD scaled paddingHorizontal bold color="#fff">Add Company</MD>
          </Flex>
          <IconButton onClick={closeSidebar} disableBackground alignCenter justifyEnd size="large" aria-label="closeSidebar">
            <Icon button icon="icon-close" fontSize="13px" color={'#fff'} />
          </IconButton>
        </Flex>
      </SidebarFilterHeader>

      <SidebarFilterTabs>
        <TabList className="tabs no-border">
          <Tab slate className="tab" onClick={() => {
                trackClick('setRegister', 'current', 'CustomerApprovalSidebar');
                setActiveTab('details')
              }
            } 
            selected={tab === 'details'}>
              Company Details
          </Tab>
          <Tab slate className="tab" disabled={!companyDetails} onClick={() => {
                trackClick('setTab', 'previous', 'CustomerApprovalSidebar');
                if (!companyDetails) {
                  alert('The Company Must Be Saved Before Adding An Address')
                } else {
                  setActiveTab('addresses')
                }
              }
            } 
            selected={tab === 'addresses'}>
              Addresses
          </Tab>
        </TabList>
      </SidebarFilterTabs>

        <SidebarFilterBody>

        </SidebarFilterBody>

        {
          tab === 'details' ?
          <SidebarFooterWrapper>
            <Button buttonStatus={true} error={get(activeCompanyDetails, 'error')} loading={get(activeCompanyDetails, 'loading')} style={{width: '100%'}} primary onClick={null}>
              {
                true ?
                'Submit Company' : ''
              }
            </Button>
          </SidebarFooterWrapper> : null
        }
    </SidebarContentWrapper>
  )
}



export default AddCompanySidebar;