import tree from 'state';
import {trackClick} from 'utility/analytics';

export function openModal({type, data = {}, view=''}) {
  console.log('opem', type)
  trackClick('openModal', type, view);
  tree.set(['modals'], {
    modalOpen: true,
    modalType: type,
    modalData: data
  });
}

export function closeModal() {
  console.log('close')
  const type = tree.get(['modals', 'modalType']);
  trackClick('closeModal', type, 'modal');
  tree.set(['modals'], {
    modalOpen: false,
    modalType: null,
    modalData: null
  });
}

