import React, { useState, useEffect, useRef } from 'react';
import history from 'historyContainer';
import { useBranch } from 'baobab-react/hooks';
import { trackClick } from 'utility/analytics';
import { MobileMenuButton, MobileMenuComponent, Menu, MenuItem, Dropdown } from './MobileMenu.styles';
import { map, get, filter } from 'lodash';
import { Link } from 'react-router-dom';

const companyRoutes = [{route: '/about-us', name: 'About Us'}, {route: '/our-model', name: 'Our Model'}]


const MobileMenu = (props) => {
  const [submenuActive, setSubmenuActive] = useState(false);
  const {alloyLists} = useBranch({
    alloyLists: ['global', 'alloyLists']
  });

  function toggleSubmenu(type) {
    setSubmenuActive(type === submenuActive ? null : type) 
  }
  

  const categoriesFormatted = map(get(alloyLists, 'groups.results'), (r) => {
    const {name, id, slug}  = r;
    return  {
      name,
      displayName:name,
      id,
      route: `n/${slug}/`,
      subcategories: map(filter(get(alloyLists, 'alloys.results'), (r) => r.metallurgy_group === id), (alloy) => {
        return {
          ...alloy,
          name: `${alloy.name}`,
          parentId: id,
          parentName: name,
          route: `n/${alloy.path}`
        }
      })
    } 
  });

  
  
  return (
    
    <MobileMenuComponent active={props.active}>


      <Menu active={props.active} className="primary-mobile-menu">
        <ul>
          <MenuItem>
            <Link to="/">Home</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/services">Services</Link>
          </MenuItem>

          <MenuItem>
            <Link to="/c/equipment">Equipment</Link>
          </MenuItem>

          <MenuItem>
            <Link to="/why-mrt">Why Us</Link>
          </MenuItem>

          <MenuItem>
            <Link to="/company">Company</Link>
          </MenuItem>

         {/* <MenuItem hasDropdown>
            <span onClick={toggleSubmenu.bind(this, 'products')}>Equipment</span>
            <Dropdown active={submenuActive === 'products'}>
              <ul>
                {
                  map(categoriesFormatted, (itm, i) => {
                    return (
                      <MenuItem style={{opacity: 1}} key={`${itm.id}-1-${i}`}>
                        <Link to={`/c/${itm.route}`}>{itm.name}</Link>
                      </MenuItem>
                    )
                  })
                }
              </ul>
            </Dropdown>
          </MenuItem>*/}

          {/*<MenuItem hasDropdown>
            <span onClick={toggleSubmenu.bind(this, 'company')}>Resources</span>
            <Dropdown active={submenuActive === 'company'}>
              <ul>
                {
                  map(companyRoutes, (itm, i) => {
                    return (
                      <MenuItem style={{opacity: 1}} key={`${itm.id}-2-${i}`}>
                        <Link to={`${itm.route}`}>{itm.name}</Link>
                      </MenuItem>
                    )
                  })
                }
              </ul>
            </Dropdown>
          </MenuItem>
*/}
          {/*<MenuItem hasDropdown>
            <span onClick={toggleSubmenu.bind(this, 'company')}>Company</span>
            <Dropdown active={submenuActive === 'company'}>
              <ul>
                {
                  map(companyRoutes, (itm, i) => {
                    return (
                      <MenuItem style={{opacity: 1}} key={`${itm.id}-2-${i}`}>
                        <Link to={`${itm.route}`}>{itm.name}</Link>
                      </MenuItem>
                    )
                  })
                }
              </ul>
            </Dropdown>
          </MenuItem>*/}
          <MenuItem>
            <Link to="/contact">Contact</Link>
          </MenuItem>
   
        </ul>
      </Menu>

      <div className="">
        <MobileMenuButton onClick={() => {
          // trackClick('login', 'Login', 'MobileMenu');
          history.push('/login')
        }} primary >Get In Touch</MobileMenuButton>
      </div>

    </MobileMenuComponent>

    
  )
}


export default MobileMenu;
