import React, {useState} from 'react'
import {canUseDOM} from 'exenv';
import { useMutation } from '@apollo/client';
import PhoneInput from 'react-phone-number-input';
import { MD, XXL, LG } from '@zendeskgarden/react-typography';
import { Formik } from 'formik';
import { cloneDeep } from 'lodash'
import { Button } from 'theme/Button';
import { FormInput, Form, FormFooter } from 'theme/Form';
import { Message, Field } from '@zendeskgarden/react-forms';
import { Row, Col } from '@zendeskgarden/react-grid';
import Flex, { FlexItem } from 'styled-flex-component';
import { variables } from 'theme/variables';
import { ADD_INQUIRY } from 'state/application/gql';
import {FullFormFieldWrapper} from 'theme/AuthForms.styles';
import {identifyUser} from 'state/authentication/effects';
const { spacing_xl } = variables;


function formValidation(values) {
  let errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
}

const ContactUsForm = (props) => {
  const [localStorageData] = useState(getLocalStorageData())
  const [addInquiry, { data }={}] = useMutation(ADD_INQUIRY);

  function getLocalStorageData() {
    try {
      return {
        email: canUseDOM ? localStorage.getItem('email') || '' : '',
        phone: canUseDOM ? localStorage.getItem('phone') || '' : '',
        first_name: canUseDOM ? localStorage.getItem('first_name') || '' : '',
        last_name: canUseDOM ? localStorage.getItem('last_name') || '' : '',
      }
    } catch(err) {
      return {}
    }
  }

  async function submitContactForm(values, { setSubmitting, setStatus }) {
    setSubmitting(true)
    const cloneValues = cloneDeep(values);
    const newValues = {
      inquiry_name: `${cloneValues.first_name} ${cloneValues.last_name}`,
      inquiry_phone: cloneValues.phone,
      inquiry_email: cloneValues.email,
      inquiry_description: cloneValues.message,
      inquiry_resource_id: 1,
      inquiry_data: props.productDetails || props.serviceDetails
    }

    let addInquiryResult;
    let error;
    try {
      addInquiryResult = await addInquiry({ variables: {input: newValues} });
    } catch(err) {
      error = err.message;
    }

    try {

      localStorage.setItem('email', cloneValues.email);
      localStorage.setItem('phone', cloneValues.phone);
      localStorage.setItem('first_name', cloneValues.first_name);
      localStorage.setItem('last_name', cloneValues.last_name);
      localStorage.setItem('user_id', cloneValues.email);

      identifyUser({
        user_id: cloneValues.email,
        first_name: cloneValues.first_name,
        last_name: cloneValues.last_name,
        email: cloneValues.email,
        phone: cloneValues.phone
      });
    } catch(err) {}
    
    if (error) {
      setStatus({error})
    } else {
      setStatus({error: false, success: true})
    }
    setSubmitting(false);
  }

  const initialValues =  { 
    first_name: localStorageData.first_name, 
    last_name: localStorageData.last_name,
    email: localStorageData.email, 
    phone: localStorageData.phone,
  }

  if (!props.beta) {
    initialValues.message = '';
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={formValidation}
      onSubmit={submitContactForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        status,
        setFieldValue
      }) => {
        return (
          status && status.success ? //status && status.success
          <Row>
            <Col sm={12} style={{position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, background: '#fff'}}>
              <Flex alignCenter justifyCenter column style={{padding: '80px 40px 40px'}}>
                <XXL navy center bold>Thank You For Contacting Us!</XXL>
                <MD paddingTopSm navy center>We Will Be In Contact Shortly With Details About Your Inquiry.</MD>
                {
                  props.onSuccessEnd ?
                  <MD paddingTopLg onClick={props.onSuccessEnd} bold blue>Back To Website</MD> : null
                }
              </Flex>
            </Col>
          </Row> :
          <Form size="large" onSubmit={handleSubmit}>
            {
              props.title ?
              <LG bold paddingBottomMd tag="h4">Contact Us With Questions or Inquiries.</LG> : null
            }
            <div>
              <Row>
                <Col  size={6} md={6}>
                  <FullFormFieldWrapper>
                    <MD bold left tag="h5">First Name</MD>
                    <FormInput
                      autoFocus={localStorageData.email ? false: true}
                      type="text" 
                      id="first_name" 
                      name="first_name" 
                      required 
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.first_name}
                      showMessage={true}
                      error={(errors.first_name && touched.first_name && errors.first_name)}
                      placeholder="Enter First Name"/>
                  </FullFormFieldWrapper>
                </Col>
                <Col  size={6} md={6}>
                <FullFormFieldWrapper>
                  <MD bold left tag="h5">Last Name</MD>
                  <FormInput
                    type="text" 
                    id="last_name" 
                    name="last_name" 
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                    showMessage={true}
                    error={(errors.last_name && touched.last_name && errors.last_name)}
                    placeholder="Enter Last Name"/>
                </FullFormFieldWrapper>
                </Col>
              </Row>

              <Row>
                <Col  sm={12} md={6}>
                  <FullFormFieldWrapper>
                    <MD bold left tag="h5">Email</MD>
                    <FormInput
                      type="email" 
                      id="email" 
                      name="email" 
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      showMessage={true}
                      error={(errors.email && touched.email && errors.email)}
                      placeholder="Enter Email"/>
                  </FullFormFieldWrapper>
                </Col>

                <Col sm={12} md={6}>
                  <FullFormFieldWrapper>
                    <MD bold left tag="h5">Phone Number</MD>
                      <PhoneInput
                        type="phone" 
                        id="phone" 
                        name="phone" 
                        country={'US'}
                        placeholder="Enter Phone Number"
                        onBlur={handleBlur}
                        value={values.phone}
                        onChange={ phone => {
                          setFieldValue('phone', phone);
                        }} />
                  </FullFormFieldWrapper>
                </Col>
             
              </Row>

            
                <Row>
                  <Col md={12}>
                    <FullFormFieldWrapper>
                      <MD bold left tag="h5">Message</MD>
                      <FormInput
                        textarea
                        autoFocus={localStorageData.email}
                        type="message" 
                        id="message" 
                        name="message" 
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                        showMessage={true}
                        error={(errors.message && touched.message && errors.message)}
                        placeholder="Enter Message"/>
                    </FullFormFieldWrapper>
                  </Col>
                </Row>          
              <Row>
                <Col md={12}>
                  {
                    status && status.error ?
                    <FormFooter>
                      <Field>
                        <Message validation={"error"}>{status.error}</Message>
                      </Field>
                    </FormFooter> : null
                  }
                  <Flex justifyCenter>
                    <FlexItem style={{width: '100%'}}>
                      <Button
                        loading={isSubmitting || undefined}
                        type="submit"
                        primary 
                        style={{marginTop: spacing_xl, marginBottom: spacing_xl}}>
                        Submit
                      </Button>
                    </FlexItem>
                  </Flex>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      }
    </Formik>
  )
};



export default ContactUsForm;