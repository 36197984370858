import React, { useState } from 'react';
import { get } from 'lodash';
import { trackClick } from 'utility/analytics';
import { useBranch } from 'baobab-react/hooks';
import history from 'historyContainer';
import Flex from 'styled-flex-component';
import { MD } from '@zendeskgarden/react-typography';
import { Dropdown, Trigger, Menu, Item } from '@zendeskgarden/react-dropdowns';
import { Button } from 'theme/Button';
import {variables} from 'theme/variables';
import Icon from 'components/Icon/Icon';
import {isAdmin} from 'utility/hasAccount';

const {
  line_height_xxxl,
  spacing_md,
  spacing_sm
} = variables;

const AccountMenuDropdown = ({session, logout}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const name = get(session, 'first_name') && get(session, 'last_name') ? `Hi, ${get(session, 'first_name')} ${get(session, 'last_name')}` : get(session, 'first_name') ? get(session, 'first_name') : 'My Account';
  return (
    <Dropdown 
      style={{width: '200px'}}
      arrow={false}
      placement="bottom-end"
      onStateChange={changes => {
        if (changes.isOpen !== menuIsOpen && Object.prototype.hasOwnProperty.call(changes, 'isOpen')) {
          setMenuIsOpen(changes.isOpen);
        }
      }}>
      <Trigger>
        <div>
          <Button whiteButtonLink black bold transparentBorder style={{padding: '0 10px'}}>
            <Flex alignCenter style={{height: line_height_xxxl, position: 'relative', marginRight: 0}}>
              <MD ellipsis>
                {
                  isAdmin() ?
                  <span style={{verticalAlign: 'middle', display: 'inline-block', width: 5, height: 5, borderRadius: '100%', background: 'red', marginRight: '4px'}}></span> : null
                }
                {name}
              </MD>
              <span style={{paddingLeft: 7, paddingTop: 2}}>
                 <Icon icon={'icon-chevron-down'} fontSize={'10px'}/>
              </span>
            </Flex>
          </Button>
        </div>
      </Trigger>
      {
        !menuIsOpen ? null :
        <Menu placement="bottom" arrow>
          <Item hoverColorLight bold value="dashboard" onClick={() => {
            trackClick('goToDashboard', 'Dashboard', 'AccountMenuDropdown');
            history.push('/dashboard')
          }}>
            <AccountMenuDropdownItem 
              icon={'icon-menu'}
              title="Dashboard" />
          </Item>


          <Item hoverColorLight bold value="logout" onClick={() => {
            trackClick('logout', 'logout', 'AccountMenuDropdown');
            logout()
          }}>
            <AccountMenuDropdownItem           
              icon={'icon-exit'}
              title="Logout" />
          </Item>
        </Menu>
      }
    </Dropdown>
  )
}


const AccountMenuDropdownItem = ({icon, title}) => {
  return (  
    <Flex alignCenter>
      <span style={{paddingLeft: spacing_sm, paddingRight: spacing_md, paddingTop: 2}}>
        <Icon icon={icon} fontSize={'15x'}/>
      </span>
      <MD>{title}</MD>
    </Flex>
  )
}


export default AccountMenuDropdown;