import {createBrowserHistory as createHistory} from 'history';
import {canUseDOM} from 'exenv';
import {assign} from 'lodash';
import queryString from 'query-string';


// Replace hash routes from old urls
if (canUseDOM && window.location.hash && window.history.replaceState) {
  const pathname = window.location.hash.split('#')[1];
  window.history.replaceState(null, null, pathname);
}

if (canUseDOM) {
  const currentLocation = window.location;
  window.originalLocation = {
    origin: currentLocation.origin,
    protocol: currentLocation.protocol,
    host: currentLocation.host,
    hostname: currentLocation.hostname,
    port: currentLocation.port,
    pathname: currentLocation.pathname,
    search: currentLocation.search,
    hash: currentLocation.hash,
    href: currentLocation.href,
  }
}

let state;
let prevState;
let history;
function addLocationQuery(history) {
  history.location = assign(
    history.location,
    {
      query: queryString.parse(history.location.search),
      previous: history.previous || (canUseDOM && window.originalLocation ? window.originalLocation : undefined)
    }
  );
}
if (canUseDOM) {
  history = createHistory();
  addLocationQuery(history);
  history.listen((s) => {
    prevState = state;
    state = s;
    state.previous = prevState;
    addLocationQuery(history);
  });

  history.pushWithParams = function(route) {
    const currentQuery = history.location.query;
    const newQuery = route.split('?')[1] ? queryString.parse(route.split('?')[1]) : {};
    const nextQuery = queryString.stringify({
      ...currentQuery,
      ...newQuery
    });
    history.push(`${route.split('?')[0]}?${nextQuery}`)
  }
  history.replaceWithParams = function(route) {
    const currentQuery = history.location.query;
    const newQuery = route.split('?')[1] ? queryString.parse(route.split('?')[1]) : {};
    const nextQuery = queryString.stringify({
      ...currentQuery,
      ...newQuery
    });
    history.replace(`${route.split('?')[0]}?${nextQuery}`)
  }
}

export function getState() {
  return state || window.location;
}


export default history;