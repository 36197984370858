import React, { useEffect, useState } from 'react';
import {canUseDOM} from 'exenv';
import ReactDOM from 'react-dom';
import { trackClick } from 'utility/analytics';
// import { confirmEmailReq } from 'state/authentication/actions';
import Spinner from 'components/Spinner/Spinner';
import history from 'historyContainer';
import { Button } from 'theme/Button';
import { variables } from 'theme/variables';
import Flex, { FlexItem } from 'styled-flex-component';
import PageViewWrapper from 'higherOrder/PageViewWrapper';
import { XXL } from '@zendeskgarden/react-typography';
import {get} from 'lodash';
import {
  ConfirmEmailPage,
  Logo,
  BackgroundImg,
  Section,
  ConfirmCard,
  BackgroundImgWrapper,
  BannerWrapper
} from './ConfirmEmail.styles';

const { logo_url, spacing_xl } = variables;

export const ConfirmEmailWrapper = (props) => {
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  
  async function submitConfirm() {
    // trackClick('submitConfirmEmail', 'Submit', 'ConfirmEmail');
    // setLoading(true)
    // const result = await confirmEmailReq(props.confirmation || get(props, 'location.query.key'));
    // if (result.success) {
    //   setConfirm(true)
    //   setError(false)
    // } else {
    //   setError(true)
    // }
    // setLoading(false);
  }

  useEffect(() => {
    submitConfirm();
  }, []);

  return (
    props.banner ?
    <ConfirmEmailBanner loading={loading} error={error} confirm={confirm} submitConfirm={submitConfirm} /> :
    <ConfirmEmail loading={loading} error={error} confirm={confirm} submitConfirm={submitConfirm} />
  )
}



export const ConfirmEmailBanner = ({loading, error, confirm, submitConfirm}) => {
  if (!canUseDOM) {
    return <div/>
  }

  if (error) {
    // TODO, what kind of messaging on error
    const Element = (
      <BannerWrapper>
      </BannerWrapper>
    );
    return ReactDOM.createPortal((
      Element
    ), document.querySelector('#in-page-modal'))
  } else {
    return null;
  }
}



const ConfirmEmail = ({loading, error, confirm, submitConfirm}) => {
  function continueToDashboard() {
    trackClick('continueToDashboard', 'Continue', 'ConfirmEmail');
    history.push('/item-wishlist');
  }

  return (
    <ConfirmEmailPage style={{padding: `0`, overflow: `hidden`}}>
      <Section dark>
        <Flex justifyCenter>
          <FlexItem>
            <Logo onClick={() => {
              trackClick('logoClick', 'logo', 'ConfirmEmail');
              history.push('/')}
            } src={logo_url} />
          </FlexItem>
        </Flex>
        <Flex justifyCenter>
          <FlexItem>
          <ConfirmCard>
            {
              loading ?
              <span>
                <Spinner size="20px"/>
                <XXL paddingTopMd bold center>Confirming Account</XXL>
              </span> :
              error ?
              <span>
                <XXL bold center>There was an Error Confirming Your Email</XXL>
                <Button primary center style={{marginTop: spacing_xl}} onClick={submitConfirm}>
                  Try Again
                </Button>
              </span> :
              confirm ?
              <span>
                <XXL bold center>Your Email Has Been Confirmed</XXL>
                <Button primary center style={{marginTop: spacing_xl}} onClick={continueToDashboard}>
                  Continue
                </Button>
              </span> : null
            }
          </ConfirmCard>
          </FlexItem>
        </Flex>
        <BackgroundImgWrapper>
          <BackgroundImg src="/images/hero-pipes.png" />
        </BackgroundImgWrapper>
      </Section>
    </ConfirmEmailPage>
  )
}


export default PageViewWrapper(ConfirmEmailWrapper, {pageName: 'ConfirmEmail'})