import React from 'react';
import Icon from 'components/Icon/Icon';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Field, Label, Input, Message, Textarea } from '@zendeskgarden/react-forms';
import {variables} from './variables';
import { map} from 'lodash';
import PhoneInput from 'react-phone-number-input';
const {spacing, spacing_sm, color_red_400, spacing_lg} = variables

const FormInputWrapper = styled.div`
  margin-top: ${spacing_sm};
`;

export const InputMediaIconWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  ${props => props.right ? `
    right: 3px;
    background: #fff;
    top: 4px;
    bottom: 4px
  ` : ''}
`;

export const GroupedItemSelectionInputItem = styled.div`
  margin-top: ${spacing_lg};
  ${props => props.noSpacing ? `
    margin-top: 0;
  ` : ''}
`;

export const Form = styled.form`
  footer {
    margin-top: ${spacing};
  }
`;

export const FormFooter = styled.div`
  margin-top: ${spacing};
  ${props => props.centeredButton ? `
    display: flex;
    justify-content: center;
  ` : ''}
`;

export const InputMediaWrapper = styled.div`
  position: relative;
`;


export const FormInput = ({wrapperStyle, phone, textarea, icon, error, options, showMessage=false, scaled, label, ...rest}) => (
  <FormInputWrapper style={wrapperStyle || {}}>
    <Field>
      {
        label ?
        <Label style={error ? {color: color_red_400} : {}}>{label}</Label> : null
      }
      <InputMediaWrapper>
        {
          options ?
          <select {...rest}>
            {
              map(options, (opt) => {
                return (
                  <option key={opt} value={opt}>{`${opt}`}</option>
                )
              })
            }
          </select> : 
          textarea ?
          <Textarea
            style={{minHeight: '90px'}}
            {...rest} 
            scaled={scaled}
            validation={error ? 'error' : undefined}
          /> : 
          phone ?
          <PhoneInput
            {...rest}
            country={'US'}
            onChange={ phone => rest.onChange({currentTarget: {value: phone}})}/> : 
          <Input
            {...rest}
            icon={icon ? 'icon' : ''}
            scaled={scaled}
            validation={error ? 'error' : undefined}
          />
        }
        {
          icon ?
          <InputMediaIconWrapper style={{width: '40px'}}>
            <Icon alignCenter justifyCenter icon={icon} fontSize="15px"/>
          </InputMediaIconWrapper> : null
        }
      </InputMediaWrapper>
      {
        error && showMessage ?
        <Message validation={"error"}>{error}</Message> : null
      }
    </Field>
  </FormInputWrapper>
);

FormInput.propTypes = {
  values: PropTypes.object,
  // error: PropTypes.oneOf(['String', 'boolean']),
  showMessage: PropTypes.bool
};
