import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
import { Page } from 'theme/Page';

const {
  color_white,
  media_md,
  media_sm,
  spacing_xl,
  spacing,
  public_font_size_sm,
} = variables;


export const AuthPage = styled(Page)`
  padding: 40px 0;
  background: #fff;
  height: 100vh;
  @media ${media_sm} {
    background: transparent;
    height: auto;
  }
  form {
    max-width: ${(props) => props.formMaxWidth || '400px'};
    width: 100%;
  }
`;

export const Section = styled.section`
  position: relative;
  .already {
    color: #a2a2a2;
    font-size: ${public_font_size_sm};
  }
  p {
    color: #a2a2a2;
    font-size: ${public_font_size_sm};
  }
`;

export const Logo = styled.img`
  height: 55px;
  width: auto;
  margin-bottom: 0px;
  @media ${media_sm} {
    margin-bottom: 30px;
  }
`

export const AuthForm = styled.div`
  padding: 20px;
  position: relative;
  z-index: 2;
  width: 340px; 
  border-radius: 4px; 
  background-color: ${color_white}; 
  width: 100%;
  input {
    padding: 0 10px;
  }
  @media ${media_sm} {
    padding: ${spacing_xl};
    box-shadow: 0 30px 50px 0 rgba(68,89,109,0.1);
  }
`

export const FullFormFieldWrapper = styled.div`
  margin-top: 25px;
  input {
    border: none;
    border: 1px solid #D0D0D0;
    width: 100%;
    padding: 0 10px;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 4px;
  }
  .react-phone-number-input {
    margin-top: 10px;
  }
  .react-phone-number-input__row {
    border: 1px solid #D0D0D0;
    border-radius: 4px;
    padding: 0 10px;
    * {
      border-bottom: none !important;
    }
    .react-phone-number-input__input--style {
      padding-left: 10px !important;
    }
    .react-phone-number-input__country {
      padding-right: 10px;
      border-right: 1px solid #ccc;
    }
  }
  textarea {
    outline: none !important;
    box-shadow: none !important;
  }

  select:not(.react-phone-number-input__country-select) {
    padding: 0 5px;
    font-size: 12px;
    line-height: 1;
    height: 27px;
    border-radius: 6px;
    position: relative;
    /* outline: 0; */
    cursor: pointer;
    padding-right: 53px;
    min-height: 42px;
    font-size: 14px;
    border: 1px solid #D0D0D0;
    width: 100%;
  }
`


export const FormFieldWrapper = styled.div`
  margin-top: ${(props) => props.spacing || spacing_xl};
  input {
    border-radius: 4px;
    border: 1px solid #D0D0D0;
    width: 100%;
    padding-bottom: 10px;
    outline: none !important;
    box-shadow: none !important;
    padding: 0 10px;
  }
  textarea {
    outline: none !important;
    box-shadow: none !important;
  }
`

export const FormWrapper = styled.div`
  margin-top: ${(props) => props.spacing || spacing_xl};
  input {
    border-radius: 4px;
    border: 1px solid #D0D0D0;
    width: 100%;
    padding-bottom: 10px;
    outline: none !important;
    box-shadow: none !important;
    padding: 0 10px;
  }
  textarea {
    outline: none !important;
    box-shadow: none !important;
  }
`

export const BackgroundImgWrapper = styled.div`
  overflow: hidden;
  position: fixed;
  height: 600px;
  width: 500px;
  left: 0; 
  right: -74%; 
  bottom: 0;
  margin: 0 auto;
  pointer-events: none;
`

export const BackgroundImg = styled.img`
  height: auto;
  width: 500px;
  opacity: 0.01;
  pointer-events: none;
  @media ${media_sm} {
   opacity: 0.03;
  }
`

export const FormTextBottom = styled.div`
  p {
    color: #a2a2a2;
    font-size: ${public_font_size_sm};
  }
`

export const Line = styled.div`
  box-sizing: border-box;
  height: 2px;
  width: 34px;
  border: 1px solid #EAEAEA;
  margin: 15px 0;
`
