import React, {useEffect} from 'react';

const GlobalAppApiRequests = (props) => {
  useEffect(() => {
  
  }, [])
  return null;
}

export default GlobalAppApiRequests;
