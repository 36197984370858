import {variables} from 'theme/variables';
const {
  custom_blue
} = variables;



const RangeField = {
  'ranges.range_group': props => {
    return `

      ${props.alignCenter ? `
        display: flex;
        align-items: center;
      ` : ''}
    `;
  },

  'ranges.multi_thumb_range.track': props => {
    return `
      background-image: linear-gradient(${custom_blue},${custom_blue});
      height: 3px;
    `;
  },

  'ranges.range_group': props => {
    return `
      padding-top: 28px
    `;
  },

  'ranges.multi_thumb_range.thumb': props => {
    const value = props['aria-valuetext'];
    const lm = {
      'l1': '-8px',
      'l2': '-11px',
      'l3': '-15px',
      'l4': '-18px'
    }
    const lmline = {
      'l1': '3px',
      'l2': '4px',
      'l3': '4px',
      'l4': '4px'
    }
    return `
      border: 3px solid ${custom_blue};
      background-color: ${custom_blue} !important;
      width: 15px;
      height: 15px;
      margin-top: -8px;
      &[aria-valuetext="${value}"] {
        &:after {
          content: "${value}";
          position: absolute;
          background: #3465e6;
          top: 0;
          padding: 2px 9px;
          color: white;
          font-size: 13px;
          border-radius: 10px;
          top: -34px;
          margin-left: ${lm[`l${String(value || '').length}`] || '-8px'};
          font-size: 11px;
        }
        &:before {
          content: "";
          position: absolute;
          background: #3465e6;
          top: -20px;
          bottom: 0;
          width: 1px;
          margin-left: ${lmline[`l${String(value || '').length}`] || '3px'};
        }
      }
    `;
  },

};

export default RangeField;
