import tree from 'state';
import * as Sentry from '@sentry/browser';
import {find} from 'lodash'

import {identify} from 'utility/analytics';

export function setupSession({user, token}) {
  localStorage.setItem('token', token);
  tree.set(['authentication', 'token'], token)
  tree.set(['authentication', 'user'], user)
  tree.commit();
}

export function logout() {
  teardownSession()
}

export function refreshSession(user) {
  const token = localStorage.getItem('token');
  tree.set(['authentication', 'token'], token)
  tree.set(['authentication', 'user'], user)
  tree.commit();
}

export function teardownSession() {
  localStorage.removeItem('token');
  tree.set(['authentication'], {})
  tree.commit();
}

export function setupResource(resources=[]) {
  if (resources.length === 1) {
    tree.set(['authentication', 'activeResource'], Number(resources[0].id))
  } else {
    const storedResource = localStorage.getItem('activeResource');
    if (storedResource && find(resources, {id: storedResource})) {
      tree.set(['authentication', 'activeResource'], Number(storedResource))
    } else {
      // prompt modal
    }
  }
}

export function getActiveResource() {
  return tree.get(['authentication', 'activeResource'])
}


export function identifyUser(session) {
  identify(session.user_id, {phone: session.phone, "first_name": session.first_name, "last_name": session.last_name, "email": session.email, "user_id": session.user_id});
  Sentry.configureScope(function(scope) {
    scope.setUser({"email": session.email, "user_id": session.user_id});
  });

  if (window.Intercom) {
    // window.Intercom("boot", {
    //   app_id: window.__INTERCOM_APP_ID__ || INTERCOM_APP_ID,
    //   user_id: session.user_id,
    //   email: session.email
    // });
  }
}