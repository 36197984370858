import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
const {
  spacing,
  custom_light_grey,
} = variables;

export const StyledDropdownMenu = styled('div')`
.triangle-wrapper {
  width: 100%;
  position: absolute; 
  margin: 0 auto;
  margin-top: 12px;
}
.triangle {
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  margin: 0 auto;
}
.wrapper-drop-down {
  display: flex;
  position: relative;
  justify-content: flex-end;
  .dropdown-main-link {
    z-index: 3;
    position: relative;
  }
  &.force-hide .drop-down {
    pointer-events: none !important;
    display: none !important;
  }
  .dropdown-link {
    display: block;
    padding-left: 25px;
    transition: all 100ms;
    width: 100%;
    &.active {
      background-color: #f7f8fb;
      color: #0168ef !important;
    }
    &:hover {
      background-color: #eaebf1;
      color: #0168ef !important;
    }
  }
  .dropdown-title {
    .defaultSemibold;
    padding-right: 8px;
    position: relative;
    cursor: pointer;
  }
  .drop-down {
    position: absolute;
    height: auto;
    left: 0;
    top: 0px;
    background: transparent;
    min-width: 128px;
    max-width: 800px;
    width: auto;
    margin-top: 20px;
    white-space: nowrap;
    animation: fadeInDropDown 500ms ease;
    padding: 0px 0;
    z-index: 2;
  }
  .link-wrapper {
    position: relative;
    .chev {
      right: 0;
      top: 0;
      position: absolute;
      width: 20px;
      height: 40px;
      opacity: 1;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-chevron-right {
        flex: 1;
        pointer-events: none;
        font-size: 8px; 
      }
    }
  }
  .drop-down-overlay {
    pointer-events: none;
    animation: fadeInDropDownOverlay 200ms;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: rgba(0,0,0,0.2);
  }
  @keyframes fadeInDropDownOverlay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeInDropDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    75% {
      transform: translateY(0);
    }
    100% {
      opacity: 1;
    }
  }

  .categories {
   border-radius: 6px;
  }
  &.with-sub {
    .categories {
      border-radius: 0;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    .subcategories {
      border-radius: 0;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .categories, .subcategories {
    display: flex;
    flex-direction: column;
    overflow: auto; 
    box-shadow: 0 15px 35px rgba(59, 59, 76, 0.42);
    margin-top: 20px;
    background-color: #fff;
    padding-top: 5px;
    a {
      display: block;
      padding: 10px 20px;
      padding-right: 40px !important;
      font-weight: 500;
    }
  }

  .subcategories {
    opacity: 1;
    transition: width 200ms;
    border-left: 1px solid #eaeaea;
    a {
      .sub-cat-title {
        color: #1D457A !important;
        &:hover {
          color: #0168ef !important;
        }
      }
    }
    &.hidden {
      border: 0;
      opacity: 0;
      width: 0;
      min-width: 0;
      margin: 0;
      padding: 0;
    }
  }

  
`