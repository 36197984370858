import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
const {
  spacing,
  custom_light_grey,
  custom_slate
} = variables;

export const StyledMenu = styled('div')`
  .dropdown-menu {
  
  .drop-down {
    display: flex;
    padding: 0;
    min-width: 100px;
  }
  .hidden {
    display: none;
  }
} 
`


export const LargeMenu = styled('div')`
  .dropdown-menu { 
    .column-group-name {
      display: flex;
      width: 100%;
      padding: 10px 20px 0;
      color: ${custom_slate};
      font-weight: 500;
      text-transform: uppercase;
      font-size: 12px;
    }
    .drop-down {
      width: 500px;
      display: flex;
      padding: 0;
      min-width: 100px;
    }
    .hidden {
      display: none;
    }
    .categories {
      display: flex;
      display: flex;
      max-width: 500px;
      flex-wrap: wrap;
      flex-direction: row;
      min-width: 500px;
      padding-bottom: 20px;
      .group {
        width: 30%;
        margin-top: 10px;
      }
    }
}

.categories-dropdown-menu {
  .categories {
    max-width: 430px !important;
    min-width: 430px !important;
  }
  .drop-down {
    left: -205px;
  }
  .triangle-wrapper {
    left: 0;
    right: 0;
  }
  .group {
    :first-child {
      width: 36% !important;
    }
  }
} 

.services-dropdown-menu {
  .categories {
    max-width: 430px !important;
    min-width: 430px !important;
  }
  .drop-down {
    left: -205px;
  }
  .group {
    width: 50% !important;
    margin-top: 10px;
  }
  .triangle-wrapper {
    left: 0;
    right: 0;
  }
} 
`
