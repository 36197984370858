import React from 'react';
import GlobalModals from 'components/GlobalModals/GlobalModals';
import GlobalSideBar from 'components/GlobalSideBar/GlobalSideBar';
import MobileDrawer from 'components/MobileDrawer/MobileDrawer';
import { ModalProvider } from 'forks/customModal'
import {FadingBackground, FadingBackgroundSidebar} from './ModalWrappers.styles.js'

const ModalWrappers = (props) => {
  return (
    <span>
      <ModalProvider backgroundComponent={FadingBackground}>
        <GlobalModals />
      </ModalProvider>

      <ModalProvider backgroundComponent={FadingBackgroundSidebar}>
        <GlobalSideBar />
      </ModalProvider>

      <ModalProvider backgroundComponent={FadingBackgroundSidebar}>
        <MobileDrawer />
      </ModalProvider>
    </span>
  )
}


export default ModalWrappers
