import { gql, useMutation } from '@apollo/client';

// export const ADD_INQUIRY = gql`
//   mutation AddInquiry($inquiry_name: String!, $inquiry_email: String!, $inquiry_phone: String!, $inquiry_description: String!) {
//     AddInquiry(inquiry_name: $inquiry_name, inquiry_email: $inquiry_email, inquiry_phone: $inquiry_phone, inquiry_description: $inquiry_description)
//   }
// `;

export const ADD_INQUIRY = gql`
  mutation AddInquiry($input: AddInquiryInput!) {
    AddInquiry(input:$input) {
      id
    }
  }
`;


export const GET_INQUIRIES = (id) => {
  return gql`
    query getLandingInquiries {
    Inquiries(inquiry_resource_id: ${id})  {
      id
      inquiry_name
      inquiry_email
      inquiry_phone
      inquiry_description
      inquiry_data
      status
      type
      inquiry_user_id
    }
  }
  `;
}

export const GET_INQUIRY_DETAILS = (id) => {
  return gql`
    query getListingDetails {
      Inquiry(id: ${id})  {
        id
        inquiry_name
        inquiry_email
        inquiry_phone
        inquiry_description
        inquiry_data
        status
        type
        inquiry_user_id
      }
    }
  `;
}