import UAParser from 'ua-parser-js';
import {merge, get, isString} from 'lodash';
import {canUseDOM} from 'exenv';

const uaParser = new UAParser();
let currentView;
let previousFullView;

if (canUseDOM && !window.analytics) {
  window.analytics = {
    track: (args) => {
      console.log('NO track YET', args)
    },
    identify: (args) => {
      console.log('NO identify YET', args)
    },
    page: (args) => {
      console.log('NO page YET', args)
    },
    ready: (args) => {
      console.log('NO ready YET', args)
    }
  };
}

export function track(event, data, contextData) {

  window.analytics.track(
    `${event}`,
    merge(getBaseProperties(), data),
    merge(getContextProperties(), contextData)
  );
}

export function trackError(event, error = '') {
  window.analytics.track(
    'error',
    merge(getBaseProperties(), {name: event, error}),
    getContextProperties()
  );
}

export function trackClick(event, label, pageLocation, data, contextData) {
  if (!isString(pageLocation)) {
    contextData = data;
    data = pageLocation;
    pageLocation = null;
  }
  track(
    event,
    merge({label, pageLocation, category: 'click'}, data),
    contextData
  );
}


export function identify(userId, traits) {
  window.analytics.identify(
    userId,
    merge(getBaseProperties(), traits),
    getContextProperties()
  );
}

export function page(name, data, options) {

  if (!previousFullView) {
    previousFullView = name;
  }
  if (previousFullView.pageType !== 'modal') {
    previousFullView = currentView;
  }
  currentView = name;

  window.analytics.page(
    `${name}`,
    merge(getBaseProperties(), {pageType: 'route'}, data),
    merge(getContextProperties(), options)
  );
}


function getBaseProperties() {
  const roles = ''
  return {
    pagePath: window.location.pathname,
    view: currentView,
    roles: roles,
  };
}

export function getContextProperties() {
  const uaResult = uaParser.getResult();
  return {
    browser: get(uaResult, 'browser'),
    device: {
      model: get(uaResult, 'device.model'),
      type: get(uaResult, 'device.type'),
      manufacturer: get(uaResult, 'device.vendor')
    },
    locale: get(window, 'navigator.language'),
    os: get(uaResult, 'os'),
    screen: {
      width: get(window, 'screen.availWidth') || get(window, 'screen.width'),
      height: get(window, 'screen.availHeight') || get(window, 'screen.height'),
      density: window.devicePixelRatio
    },
    integrations: {
      'Google Analytics': true,
      'Google AdWords': false,
      'Facebook Audiences': false,
      'Twitter Ads': false,
      'Facebook Conversions': false
    }
  };
}