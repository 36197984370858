import React, { useEffect } from 'react';
import Icon from 'components/Icon/Icon';
import useWindowSize from 'hooks/useWindowSize';
import {trackClick} from 'utility/analytics';
import {useBranch} from 'baobab-react/hooks';
import {variables} from 'theme/variables';
// import { SearchInput } from 'theme/SearchInput';
import Flex from 'styled-flex-component';
import { Row, Col } from '@zendeskgarden/react-grid';
import { IconButton } from '@zendeskgarden/react-buttons';
import history from 'historyContainer';
import {logout} from 'state/authentication/actions';
import Platform from 'components/Platform/Platform';
import { get } from 'lodash';

import AccountMenuDropdown from './components/AccountMenuDropdown/AccountMenuDropdown';
// import NotificationDropdown from './components/NotificationDropdown/NotificationDropdown';

import {toggleDrawer} from 'state/sidebars/actions';
import {toggleExpand} from 'state/sidebarPage/actions';


import {
  MenuColumn,
  // SearchWrapperCol,
  AppHeaderComponentSidebar,
  LogoColumn,
  ActionColumn,
  HeaderGrid,
  Logo,
  FacilitatorLogo
} from './app-header.styles.js'

const {
  logo_url
} = variables;

 
const AppHeader = (props) => { 
  const {session} = useBranch({
    session: ['authentication', 'session'],
  }); 
  const media = get(session, 'media');
  // const icon = get(session, 'roles.facilitator.data.company.icon');


  async function appLogout() {
    trackClick('logout', 'logout', 'AppHeader');
    const logoutRequest = await logout();
    if (!logoutRequest.error) {
      history.push('/login');
    } else {
      alert('There was an error signing out.')
    }
  }

  const windowSize = useWindowSize();
  const expandSidebar = windowSize && (windowSize.innerWidth > 1024 || windowSize.innerWidth <= 768);
  useEffect(() => {
    if (windowSize) {
      toggleExpand(expandSidebar);
    }
  }, [expandSidebar]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppHeaderComponentSidebar className="app-header-comp">
      <HeaderGrid fluid>
        <Row gutters={false} alignItems="center">
          <LogoColumn>
            <Flex alignCenter>
              <Logo
                style={{width: '250px'}}
                src={logo_url}  
                onClick={() => {
                  trackClick('logoClick', 'logo', 'AppHeader');
                  history.push('/dashboard')
                }}
              />
            </Flex>
          </LogoColumn>

          <Platform component={Col} mobile alignCenter justifyEnd size={2}>
            <IconButton style={{marginBottom: 10}} onClick={toggleDrawer} disableBackground alignCenter justifyEnd size="large" aria-label="Menu">
              <Icon icon="icon-align-text" fontSize={'18px'}/>
            </IconButton>
          </Platform>

          <ActionColumn size={12} sm={false} paddingRight>
            <Row style={{minWidth: '100%'}} alignItems="center" justifyContent="between">
              
              <Col>
                <Row alignItems="center">
                  {/*<SearchWrapperCol>
                    <SearchInput size="xl" placeholder="Search Vendors & Inventory" />
                  </SearchWrapperCol>*/}
                </Row>
              </Col>

              
              <MenuColumn style={{marginLeft: 20}} size={'auto'}>
                <Row alignItems="center">
                  <Col size="auto">
                    {/*<NotificationDropdown />*/}
                  </Col>
                  <Col size="auto">
                    <AccountMenuDropdown session={session} logout={appLogout} />
                  </Col>
                </Row>
              </MenuColumn>
            </Row>
          </ActionColumn>
        </Row>
      </HeaderGrid>
    </AppHeaderComponentSidebar>
  )
}


export default AppHeader;
