import styled from 'styled-components/macro';
import {variables} from 'theme/variables';

const {
  app_header_height,
  app_footer_height,
  app_mobile_header_height,
  app_mobile_footer_height,
  media_sm,
  media_md,
  custom_background_color,
  color_white
} = variables;

export const AppContainer = styled.section`
  padding-top: ${props => props.hiddenHeader ? '0px' : (props => props.publicHeader ? (props => props.transparentHeader ? '0px' : '66px') : app_mobile_header_height)};
  min-height: ${props => props.publicHeader ? `calc(100vh - 573px)` : `calc(100vh - ${app_mobile_footer_height})`};
  background-color: ${props => props.publicHeader ? color_white : custom_background_color};
  @media ${media_md} {
    min-height: ${props => props.publicHeader ? `calc(100vh - 573px)` : `calc(100vh - ${props.hiddenFooter ? '0px' : app_footer_height})`};
  }
  @media ${media_sm} {
    padding-top: ${props => props.hiddenHeader ? '0px' : (props => props.publicHeader ? (props => props.transparentHeader ? '0px' : '66px') : app_header_height)};
  }  
`

export const FullModalWrapper = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 20;
`

export const ModalScrollContainer = styled.section`
  overflow-y: auto;
  overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`
