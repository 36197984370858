import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
import { Button } from 'theme/Button';
const {
  custom_dark_blue,
  color_white,
  public_font_size_md,
  font_weight_regular,  
  media_lg
} = variables;

export const MobileMenuComponent = styled.div`
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background: ${custom_dark_blue};
  padding: 44px 24px 0px;
  pointer-events: none;
  transform: translateX(300px);
  z-index: 3;
  transition: transform 0.6s cubic-bezier(0.220, 0.265, 0.085, 0.980) 0.3s, clip-path 0.4s cubic-bezier(0.220, 0.265, 0.085, 0.980) 0.2s;
  will-change: auto;
  @media ${media_lg} {
    display: none;
  }

  


  .menu-background {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0px;
    left: 0px;
    z-index: -1;
    background: rgb(0, 29, 67);
    pointer-events: none;

    .circle-1 {
      transform: translateX(-300px);
      transition: transform 0.5s cubic-bezier(0.220, 0.265, 0.085, 0.980) 0.5s;
      will-change: auto;
    }
    .circle-2 {
      transform: translateX(300px);
      transition: transform 0.5s cubic-bezier(0.220, 0.265, 0.085, 0.980) 0.7s;
      will-change: auto;
    }
  }

  ${props => props.active ? `
    transform: translateX(0%);
    pointer-events: all;
    transition-delay: 0.0s;
    .menu-background .circle-1, .menu-background .circle-2 {
      transform: translateX(0px);
    }
  `: ''}

`;




export const MobileMenuButton = styled(Button)`
  width: 100%;
  border-radius: 4px;
  opacity: 1;
`;

export const Menu = styled.div`
  margin-bottom: 46px;
  ul {
    list-style-type: none;
    li {
      opacity: 1;
      will-change: auto;
      a, span {
        color: ${color_white} !important;
        font-size: ${public_font_size_md};
        font-weight: ${font_weight_regular};
        line-height: 18px;
        padding: 18px 0px;
        display: block;
      }
    }
  }

`;


export const MenuItem = styled.li`
    
`;

export const Dropdown = styled.div`
    margin-left: 24px;
    height: 0;
    transition: height 0.4s ease-out;
    overflow: hidden;

    ${props => props.active ? `
      height: auto;
    `: ''}
`;
