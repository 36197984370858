import tree from 'state';
import {canUseDOM} from 'exenv';

export function hasSession() {
  const session = tree.get(['authentication', 'user', 'email']);
  return session || (canUseDOM && window.OFFLINE_MODE)
}

export function isFacilitator() {
  const roles = tree.get(['authentication', 'session', 'roles']) || {};
  const dashboardType = tree.get(['application', 'accountType']);
  const facilitator = roles.facilitator;
  return true// ((dashboardType && dashboardType === 'facilitator') || !dashboardType) && (facilitator || dashboardType === 'facilitator')
}

export function isAdmin() {
  const roles = tree.get(['authentication', 'session', 'roles']) || {};
  const dashboardType = tree.get(['application', 'accountType']);
  const admin = roles.admin;
  return ((dashboardType && dashboardType === 'admin') || !dashboardType) && (admin || dashboardType === 'admin')
}

export function isEndUser() {
  const roles = tree.get(['authentication', 'session', 'roles']) || {};
  const dashboardType = tree.get(['application', 'accountType']);
  const endUser = roles.buyer || roles.seller || roles.buyer_seller;
  return ((dashboardType && dashboardType === 'end_user') || !dashboardType) && (endUser || dashboardType === 'end_user')
}

export function isBetaTester() {
  const email = tree.get(['authentication', 'session', 'email']) || '';
  const isTester = email.indexOf('mritechnologies.com') > -1 && email.indexOf('+') > -1;
  return isTester;
}


export function getClientId() {
  return tree.get(['authentication', 'session', 'roles', 'facilitator', 'data', 'company', 'id']);
}

