
import * as effects from './effects';
import { map, get } from 'lodash';
import tree from 'state';


export async function setRoutedModalIsAnimating(state) {
  tree.set(['application', 'routedModalIsAnimating'], state);
}

export async function setGlobalSearchStatus(state) {
  tree.set(['application', 'searchStatus'], state);
}

export async function setRoutedModalState(state, {element, transitioning}) {
  if (element && transitioning === undefined) {
    tree.set(['application', 'routedModalState'], state === 'exit' ? 'exit' : 'exited');
  }
  if (element && transitioning === false) {
    tree.set(['application', 'routedModalState'], state === 'enter' ? 'enter' : 'entered');
  }
  tree.commit()
}


//EXTERNAL TREE
export async function setFilters({path, selected}) {
  tree.set(path, selected);
}