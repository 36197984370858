import React, { useState } from 'react';
import Icon from 'components/Icon/Icon';
import { Link } from 'react-router-dom';
import {useBranch} from 'baobab-react/hooks';
import Modal from "forks/customModal";
import {toggleDrawer} from 'state/sidebars/actions';

import { MenuItem, Close } from './MobileDrawer.styles.js';



const StyledDrawer = Modal.styled`
  width: ${props => props.width || '20rem'};
  height: 100vh;
  position: fixed;
  padding: 1rem;
  top: 0px;
  bottom: 0;
  align-items: flex-start;
  justify-content: center;
  background-color: #fff;
  opacity: 1;
  min-width: 200px;
  box-shadow: 0 13px 76px rgba(45, 54, 79, 0.16);
  transform: ${props => props.opacity ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0px, 0)'};
  transition: transform cubic-bezier(0.2, 0, 0, 1) 240ms 10ms;
  overflow: hidden;
`
 
const GlobalMobileDrawer = (props) => {
  const [opacity, setOpacity] = useState(0);
  const {drawerOpen} = useBranch({
    drawerOpen: ['sidebars', 'mobileDrawerOpen']
  });

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1)
    }, 20);
  }

  function beforeClose() {
    return new Promise(resolve => {
      setOpacity(0)
      setTimeout(resolve, 300);
    });
  }

  return (
    <StyledDrawer
      isOpen={drawerOpen}
      onBackgroundClick={toggleDrawer}
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      onEscapeKeydown={toggleDrawer}
      opacity={opacity}
      allowScroll={true}
      backgroundProps={{ opacity: opacity }}>
        <Close onClick={toggleDrawer}>
          <Icon icon="icon-close" fontSize={'15px'}/>
        </Close>
        <ul>
          <MenuItem onClick={toggleDrawer} >
            <Link to="/">Home</Link>
          </MenuItem>
          <MenuItem onClick={toggleDrawer} >
            <Link to="/why-mrt">Why MRT</Link>
          </MenuItem>
          <MenuItem onClick={toggleDrawer} >
            <Link to="/services">Services</Link>
          </MenuItem>
          <MenuItem onClick={toggleDrawer} >
            <Link to="/contact">Contact</Link>
          </MenuItem>
        </ul>
        
    </StyledDrawer>
  )
}


export default GlobalMobileDrawer;