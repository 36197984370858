import React, { useEffect } from 'react';
import {addClass} from 'utility/domUtils';
import { MD, SM } from '@zendeskgarden/react-typography';
import ReactDOM from 'react-dom'
import {canUseDOM} from 'exenv';
import isIe11 from 'utility/isIe11';
import {IeBanner, SupportWrapper} from './NotificationBanner.styles'

const NotificationBanner = ({notification, ...rest}) => {
  useEffect(() => {
    if (isIe11()) {
      addClass(document.getElementsByTagName('html')[0], 'banner-visible');
    }
  }, [])

  if (!isIe11()) {
    return <span style={{display: 'none'}}/>
  }


  const IeBannerComp = (
    <div>
    <IeBanner>
      <div style={{display: 'inline-block', verticalAlign: 'middle', paddingTop: '17px'}}>
        <MD style={{fontSize: "17px", lineHeight: '25px'}} noMargin bold>Your web browser (Internet Explorer) is out of date, and is no longer supported by Windows.</MD>
        <MD noMargin>Note: For a better experience please download one of these up-to-date free browsers</MD>
      </div>
      <div style={{display: 'inline-block', float: 'right'}}>
        <SupportWrapper><img style={{width: '50px', height: '50px'}} onClick={() => window.open("https://www.microsoft.com/en-us/edge")} src="/images/edge.png" alt=""/><SM style={{marginTop: '10px'}}>Edge</SM></SupportWrapper>
        <SupportWrapper><img style={{width: '50px', height: '50px'}} onClick={() => window.open("https://www.google.com/chrome/")} src="/images/chrome.png" alt=""/><SM style={{marginTop: '10px'}}>Chrome</SM></SupportWrapper>
        <SupportWrapper><img style={{width: '50px', height: '50px'}} onClick={() => window.open("https://www.mozilla.org/en-US/firefox/new/")} src="/images/firefox.png" alt=""/><SM style={{marginTop: '10px'}}>Firefox</SM></SupportWrapper>
      </div>

    </IeBanner>
    </div>
  );


  if (!canUseDOM) {
    return <div/>
  }
  return ReactDOM.createPortal((
    IeBannerComp
  ), document.querySelector('#notif-banner'))
}

export default NotificationBanner