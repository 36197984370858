import React from 'react';
import {get} from 'lodash';
import {closeModal} from 'state/modals/actions';
import Icon from 'components/Icon/Icon';
import { MD, XL } from '@zendeskgarden/react-typography';
import styled from 'styled-components/macro';
import Flex from 'styled-flex-component';
import PageViewWrapper from 'higherOrder/PageViewWrapper';
import EditListingForm from 'components/BasicForms/EditListingForm';



export const Wrapper = styled.section`
  width: 600px;
  max-width: 90%;
  .wrapper {
    width: 100%;
  }
  form {
    width: 100%;
    button {
      margin-bottom: 0 !important;
      width: 100%;
    }
  }
  .email-link {
    padding: 10px;
    background: #eef0f3;
    text-align: center;
    width: 100%;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    a {
      color: #5a5a5a !important;
    }
  }
  .icon-close {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  
`

const EditListingModal = (props) => {
  const listing = get(props, 'listing');
  return (
    <Wrapper style={{margin: '0 auto', maxWidth: '800px', ...(props.styles || {margin: '0 auto'})}}>
      <Flex className="wrapper" column alignCenter justifyBetween style={{padding: ' 30px'}}>
        <EditListingForm listing={listing} onSuccessEnd={closeModal} />
      </Flex>
    </Wrapper>
  )
}

export default PageViewWrapper(EditListingModal, {pageType: 'modal', pageName: 'EditListingModal', ignoreScrollTop: true});