const ToolTipTheme = {
  'tooltip.light_tooltip': props => {
    return `
      ${props.offsetTop ? `
        margin-top: ${props.offsetTop};
      ` : ''}
    `
  },

};

export default ToolTipTheme;
