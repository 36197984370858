import React, {useState} from 'react'
import { useMutation } from '@apollo/client';
import Icon from 'components/Icon/Icon';
import { SM, MD, XL, XXL } from '@zendeskgarden/react-typography';
import { Formik, FieldArray } from 'formik';
import { reduce, mapValues, startCase, cloneDeep, map, some } from 'lodash'
import { Button } from 'theme/Button';
import { FormInput, Form } from 'theme/Form';
import { Row, Col } from '@zendeskgarden/react-grid';
import Flex, { FlexItem } from 'styled-flex-component';
import { variables } from 'theme/variables';
import {
  FullFormFieldWrapper
} from 'theme/AuthForms.styles';
import { UPDATE_LISTING, ADD_LISTING, DELETE_LISTING } from 'state/listingsManagement/gql';

import Uploader from 'components/CloudinaryUploader/CloudinaryUploader';

const { spacing_xl, custom_red, custom_blue } = variables;


const mainKeys = {"listing_name":{}, "listing_brand":{},"listing_model":{},"listing_slug":{},"listing_style":{},"listing_type":{}, "image":{dropzone: true, size: 12}, "listing_description":{size: 12}}
const boolKeys = {"active":{}}
const dynamicKeys = {"listing_data":{
  features: {},
  specs: {}, 
}}

const QuickInquiryForm = (props) => {
  const [addListing] = useMutation(ADD_LISTING);
  const [updateListing] = useMutation(UPDATE_LISTING);
  const [deleteListing, deleteStatus] = useMutation(DELETE_LISTING);




  async function submitInquiryForm(values, { setSubmitting, setStatus }) {
    setSubmitting(true);
    const cloneValues = cloneDeep(values);
    cloneValues.listing_data = reduce(cloneValues.listing_data, (acc, v, k) => {
      acc[k] = map(v, ({name}) => name);
      return acc
    }, {});

    if (!props.listing.id) {
      cloneValues.listing_resource_id = 1;
      cloneValues.approved = true;
    }

    cloneValues.active = Boolean(cloneValues.active);

    let updateListingReult;
    try {
      updateListingReult = props.listing.id ? await updateListing({ variables: {id: props.listing.id, input: cloneValues} }) : await addListing({ variables: {input: cloneValues} });
    } catch(err) {
      setStatus({error: err.message})
    }
    
    setSubmitting(false);
    return updateListingReult;
  }

  async function removeListings({setStatus}) {
    if (window.confirm(`Are you sure you want to Delete this Listings?`)) {
      try {
        let deleteListings = await deleteListing({ variables: {id: props.listing.id} });
        alert('Successfully Deleted')
      } catch(err) {
        setStatus({error: err.message});
      }
    }
  }

  const [initialValues] = useState(setInitialData())

  function setInitialData() {
    const main =  reduce(mainKeys, (accum, v, k) => {
      accum[k] = props.listing ? (props.listing[k] || '') : '';
      return accum
    }, {});

    const bool =  reduce(boolKeys, (accum, v, k) => {
      accum[k] = props.listing ? (props.listing[k] || false) : '';
      return accum
    }, {});

    const dynam =  reduce(dynamicKeys, (accum, v, k) => {
      accum[k] = props.listing ? (props.listing[k] ? cloneDeep(props.listing[k]) : '') : '';
      accum[k] = mapValues(accum[k], (v) => {
        if (v && v.length === 0) {
          v[0] = {name: "", id: Math.random()}
        } else {
          v = map(v, (val) => ({name: val, id: Math.random()}))
        }
        return v;
      })
      return accum
    }, {});
 
    return {...main, ...bool, ...dynam}
  }



  return (
    <Formik
      initialValues={initialValues}
      validate={undefined}
      onSubmit={submitInquiryForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        status,
        setStatus,
        // setFieldTouched,
        setFieldValue
      }) => {

        function formatMain() {
          return map(mainKeys, (v, k) => {
            const name = startCase(k).replace('Listing', '');
            return (
              <Col key={k} size={12} md={v.size || 6}>
                <FullFormFieldWrapper>
                  <MD bold left tag="h5">{name}</MD>
                  {
                    k === 'image' && values[k]?
                    <img style={{width: 100}} src={values[k]} /> : null
                  }
                  <FormInput
                    type={"text"} 
                    id={k} 
                    textarea={k === 'listing_description'}
                    name={k} 
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[k]}
                    showMessage={true}
                    error={(errors[k] && touched[k] && errors[k])}
                    placeholder={`Enter ${name}`}/>
                  {
                    v.dropzone ?
                    <Uploader setFieldValue={setFieldValue}/> : null
                  }
                </FullFormFieldWrapper>
              </Col>
            );
          })
        }

        function formatBool() {
          return map(boolKeys, (v, k) => {
            const name = startCase(k);
            return (
              <Col key={`${k}-e`} size={12} md={v.size || 6}>
                <FullFormFieldWrapper>
                  <MD bold left tag="h5">{name}</MD>
                  <FormInput
                    type={"text"} 
                    id={`${k}`}
                    options={[true, false]}
                    textarea={k === 'listing_description'}
                    name={`${k}`}
                    required 
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[k]}
                    showMessage={true}
                    error={(errors[k] && touched[k] && errors[k])}
                    placeholder={`Enter ${name}`}/>
                </FullFormFieldWrapper>
              </Col>
            );
          })
        }

        return (
          status && status.success ?
          <Row>
            <Col sm={12}>
              <Flex alignCenter justifyCenter column style={{marginTop: 20, padding: '20px 0px 0'}}>
                <XXL bold blue>Success.</XXL>
                <Button type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  props.onClose();
                }} 
                primary 
                style={{marginTop: '30px'}}>
                Done
              </Button>
              </Flex>
            </Col>
          </Row> :
          <Form size="large" onSubmit={handleSubmit}>
            
            <XL paddingBottomSm style={{paddingTop: '10px', fontSize: '25px', lineHeight: '35px'}} center black navy>Listing</XL>

            <Row>
              {formatMain()}
              {formatBool()}
              <Col size="12">
                <Row>
                  <RenderItems {...{keyName: 'features', values, errors, touched, handleChange }}/>
                  <RenderItems {...{keyName: 'specs', values, errors, touched, handleChange }}/>
                </Row>
              </Col>
            </Row>
               

            {
              status && status.error ?
              <SM error paddingTopSm>Error: {status.error}</SM> : null
            }
          

            <Row>
              <Col md={12}>
                <FlexItem style={{width: '100%'}}>
                  <Button
                    error={status && status.error}
                    buttonStatus={true}
                    disabled={false}
                    loading={isSubmitting || undefined}
                    type={"submit"}
                    primary 
                    style={{marginTop: '30px', marginBottom: spacing_xl}}>
                    Submit
                  </Button>

                  <SM style={{margin: '0 auto', width: '150px'}} bold center paddingTopMd error hover onClick={() => removeListings({setStatus})}>{deleteStatus.loading ? 'Deleting' : 'Delete Listing'}</SM>
                </FlexItem>
              </Col>
            </Row>

          </Form>
        )}
      }
    </Formik>
  )
};




function RenderItems({values, keyName="features", errors, touched, handleChange }) {
  return (
    <Col size={12} md={12}>
      <MD paddingTopMd bold navy left tag="h5">{startCase(keyName)}</MD>
      <FieldArray
      name={`listing_data[${keyName}]`}
      render={(arrayHelpers) => {
        return (
        <div>
        {
          map(values['listing_data'][keyName], (item, i, arr) => {
            return (
              <Flex alignCenter center key={`${item.id}-${i}`}>
                <FullFormFieldWrapper style={{flex: 1, marginTop: 0, display: 'flex', alignItems: 'center'}}>
                  <FormInput
                    type="text" 
                    id={`listing_data[${keyName}][${i}].name`}
                    name={`listing_data[${keyName}][${i}].name`}
                    autoComplete={keyName}
                    onChange={handleChange}
                    value={item.name}
                    showMessage={true}
                    error={(errors[keyName] && touched[keyName] && errors[keyName])}
                    wrapperStyle={{width: '100%', flex: 1, maxWidth: '100%'}}
                    placeholder={`Enter ${startCase(keyName)} Name`}/>
                </FullFormFieldWrapper>
                {
                  (i === 0 && arr.length === 1) ? null :
                  <MD link style={{color: custom_red, fontSize: '22px', padding: '24px 0px 13px 15px', marginTop: '7px'}} type="button" onClick={() => arrayHelpers.remove(i)}>
                    <Icon pointer fontSize="9px" color={custom_red} icon="icon-close" style={{top:'auto', right: 'auto', position: 'relative'}} />
                  </MD>
                 }
              </Flex>
            )
          })
        }
        <Button disabled={some(values[keyName], ({name}) => !name)} blue iconColor={custom_blue} link iconBefore={"icon-circle-plus"} style={{color: 'red', width: '125px', margin: '18px auto 0', cursor: 'pointer', display: 'flex'}} onClick={() => {
          arrayHelpers.push({name: "", id: ""})
        }} type="button" paddingTopMd bold left tag="h5">
          Add A {startCase(keyName)}
        </Button>
      </div>
      )}} />
    </Col>
  )
}






export default QuickInquiryForm;
