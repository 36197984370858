import React, { useState } from 'react';
import { trackClick } from 'utility/analytics';
import { useBranch } from 'baobab-react/hooks';
import {variables} from 'theme/variables';
import { Button } from 'theme/Button';
import { XL, MD } from '@zendeskgarden/react-typography';
import Icon from 'components/Icon/Icon';
import Flex from 'styled-flex-component';
import PageViewWrapper from 'higherOrder/PageViewWrapper';

const { custom_blue } = variables

const ConfirmEmailModal = (props) => {
  const [confirm, setConfirm] = useState(false);
  const {email} = useBranch({
    email: ['authentication', 'session', 'email']
  });
  
  function confirmEmail() {
    trackClick('confirmEmail', 'Confirm Now', 'ConfirmEmailModal');
    setConfirm(true);
  }
  return (
    <div style={{maxWidth: '800px', ...(props.styles || {})}}>
      <Flex column alignCenter justifyBetween style={{padding: ' 30px 50px'}}>
        <Icon icon="icon-email-fill" color={custom_blue} fontSize={'90px'}/>
        {
          confirm ?
          <span>
            <XL headline style={{ padding: '30px 0 0', maxWidth: '400px', textAlign: 'center'}} bold> An email is on its way. </XL>
            <MD headline style={{ padding: '10px 0', maxWidth: '400px', textAlign: 'center'}} bold> An email has been sent to {email} </MD>
          </span> :
          <XL headline style={{ padding: '30px 0', maxWidth: '400px', textAlign: 'center'}} bold> Pleasee Confirm Your Email </XL>
        }
        {
          !confirm ?
          <Button size="large" onClick={confirmEmail} primary>Confirm Now</Button> : null
        }
      </Flex>
    </div>
  )
}

export default PageViewWrapper(ConfirmEmailModal, {pageType: 'modal', pageName: 'ConfirmEmailModal'});