import React, {Component} from 'react';
import menuAim from 'higherOrder/menuAim';
import {NavLink as Link} from 'react-router-dom';
import NavigationDropdownMenu from './NavigationDropdownMenu';
import {get, find, map} from 'lodash';
import {LargeMenu} from './ProductsDropdownMenu.styles';
import {SERVICES} from 'routes/Categories/routes/CategoryDetails/components/CategoriesOffered/categoriesData'


const listOfCategories = SERVICES;

class ProductsDropdownMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.initMenuAim({
      menuRef: this.menuRef,
      delay: 300,
      tolerance: 75
    });
  }

  render() {
    return (
      <LargeMenu>
        <NavigationDropdownMenu
          link={`/c/equipment`}
          customTitle="Equipment"
          className={`dropdown-menu categories-dropdown-menu`}
          onClickMenu={this.onClickMenu.bind(this)}
          onClose={this.onClose.bind(this)}
          {...this.props}>
          {
            (p) => {
              return (
                <div
                  className="categories"
                  onMouseLeave={this.props.handleMouseLeaveMenu}
                  ref={(r) => this.menuRef = r}>
                  {
                    map(listOfCategories, (group) => {
                      return (
                        <div className="group" key={group.title}>
                          <div className="column-group-name">{group.title}</div>
                          {
                            map(group.services, (cat, i) => {
                              return (
                                <div className="link-wrapper" key={i}>
                                  <Link
                                    key={i}
                                    to={`/c/equipment/${group.type}/${cat.slug}`}
                                    className={`dropdown-link ${cat.title === get(this.state, 'selectedCategory.title') ? 'active' : ''}`}
                                    onClick={(e) => {
                                      p.hideMenu(e);
                                      this.onClickCategory.bind(this, cat.title)
                                    }}>
                                      <h5>
                                        {cat.name || cat.title}
                                      </h5>
                                  </Link>
                                </div>
                              )
                            })
                          }
                        </div>
                      );
                    })
                  }
                </div>
              )
            }
          }
      </NavigationDropdownMenu>
      </LargeMenu>
    );
  }


  onClickMenu() {
    return !this.state.selectedCategory;
  }

  onClose() {
   this.setState({selectedCategory: false});
  }

  onClickCategory(name, e) {
    const category = find(listOfCategories, {name});
    this.setState({
      selectedCategory: category
    });
    this.props.handleMouseLeaveMenu({}, e);
  }


}

export default menuAim(ProductsDropdownMenu);