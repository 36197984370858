import { gql, useMutation } from '@apollo/client';

export const ADD_LISTING = gql`
  mutation AddListing($input: AddListingInput!) {
    AddListing(input:$input) {
      id
      listing_name
      listing_resource_id
      listing_description
      listing_price
      listing_data
      listing_brand
      listing_model
      listing_part_number
      listing_slug
      listing_style
      listing_type
      delete_date
      image        
      deleted_at
    }
  }
`;


export const UPDATE_LISTING = gql`
  mutation UpdateListing($id: Int, $input: UpdateListingInput!) {
    UpdateListing(id:$id, input:$input) {
      id
      listing_name
      listing_resource_id
      listing_description
      listing_price
      listing_data
      listing_brand
      listing_model
      listing_part_number
      listing_slug
      listing_style
      listing_type
      delete_date
      image        
      deleted_at
    }
  }
`;

export const DELETE_LISTING = gql`
  mutation($id: Int) {
    DeleteListing(id: $id) {
      id
      listing_name
      listing_resource_id
      listing_description
      listing_price
      listing_data
      listing_brand
      listing_model
      listing_part_number
      listing_slug
      listing_style
      listing_type
      delete_date
      image        
      deleted_at
    }
  }
`;