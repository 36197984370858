import React, { useRef, useEffect, useState } from 'react';
import Icon from 'components/Icon/Icon';
import { Button as ZendeskButton } from '@zendeskgarden/react-buttons';
import {variables} from './variables';
import Spinner from 'components/Spinner/Spinner'
import Flex, { FlexItem } from 'styled-flex-component';
import {LinkButton} from 'theme/ThemeProviderOverrides/Button';
import { Link } from 'react-router-dom';
const {spacing_xs, line_height_xxl, color_white, custom_blue} = variables

export const Button = ({onResetButtonState, to, anchor, onClick, buttonStatus, error, loading, children, solo, alignCenter, justifyCenter, icon, iconBefore, iconAfter, iconSize, iconColor, publicButton,  ...rest}) => {
 const statusTimer = useRef();
 const [buttonState, setButtonState] = useState(false);
  useEffect(() => {
    if (buttonStatus) {
      if (loading) {
        setButtonState('isLoading');
      } else if(buttonState === 'isLoading' && !loading && !error) {
        setButtonState('isSuccess')
        statusTimer.current = setTimeout(() => setButtonState(''), 1000)
      } else if(buttonState === 'isLoading' && !loading && error) {
        setButtonState('isError')
        statusTimer.current = setTimeout(() => setButtonState(''), 1000)
      } else {
        setButtonState('');
        if (onResetButtonState) {
          onResetButtonState();
        }
      }
    }
    return () => {
      clearInterval(statusTimer.current);
    }
  }, [loading, error]);

  const hasState = buttonState === 'isSuccess' || buttonState === 'isError'
  const Component = anchor ? LinkButton : ZendeskButton;
  return (
    <Component {...rest} onClick={loading ? null : onClick} loading={loading ? `${loading}` : undefined} publicButton={publicButton} buttonState={buttonState}>
      { publicButton ? <Icon pointer color={color_white} icon="icon-arrow-right-short" /> : null }
      {
        hasState ? 
        <Flex alignCenter justifyCenter style={{marginLeft: `0px`}}>
          <FlexItem style={{paddingRight: `${spacing_xs}`}}>
            <Flex style={{minHeight: rest.size === 'large' ? line_height_xxl : line_height_xxl}} alignCenter justifyCenter>
              {
                buttonState === 'isSuccess' ?
                <Icon pointer color={color_white} fontSize={iconSize || '20px'} icon={'icon-check-outline'} /> :
                <Icon pointer color={color_white} fontSize={iconSize || '20px'} icon={'icon-close-outline'} />
              }
            </Flex>
          </FlexItem>
        </Flex> :
        <Flex alignCenter justifyCenter style={{marginLeft: `0px`}}>
          {
            loading ?
            <FlexItem style={{paddingRight: `${spacing_xs}`}}>
              <Flex style={publicButton ? {} : {minHeight: rest.size === 'large' ? line_height_xxl : line_height_xxl}} alignCenter justifyCenter>
                <Spinner color={rest.link ? (rest.color || custom_blue) : color_white} size={'10px'} />
              </Flex>
            </FlexItem> : null
          }
          {
            iconBefore ?
            <Icon pointer color={iconColor} fontSize={iconSize || '15px'} style={{paddingRight: `${spacing_xs}`}} icon={iconBefore} /> : null
          }
          {
            (loading && !solo) || !loading ?
            children : null
          }
          {
            iconAfter ?
            <Icon pointer color={iconColor} fontSize={iconSize || '15px'} style={{paddingLeft: `${spacing_xs}`}} icon={iconAfter} /> : null
          }
          {
            icon ?
            <Icon pointer color={iconColor} fontSize={iconSize || '15px'} icon={icon} /> : null
          }
        </Flex>
      }
      {anchor ? <Link to={to}></Link> : null}
    </Component>
  )
};

