// OVERIDE CSS - TODO IF CHANGED
import {keyframes, css} from 'styled-components/macro';
import {variables} from 'theme/variables';
const {
  custom_grey,
  custom_black,
  font_size_sm,
  font_size_md,
  custom_blue,
  color_white,
  font_weight_semibold,
  custom_light_grey,
  custom_silver,
  color_red_400,
  custom_orange,
  custom_placeholder
} = variables;


const fadeIn = keyframes`  
  from   {
    opacity: 0; 
    transform: translate3d(0, -10px, 0);
  }
  to  {
    opacity: 1; 
    transform: translate3d(0, 0, 0);
  }
`;

const animation = props =>
  css`
    ${fadeIn} 300ms ease;
  `

const DropDown = {
  'dropdowns.menu':props => {
    return `
      ${props.overflowLarge ? `
        min-width: 600px;
        @media (max-width: 650px) {
          min-width: 550px;
        }
        @media (max-width: 550px) {
          min-width: 450px;
        }
        @media (max-width: 470px) {
          min-width: 400px;
        }
        @media (max-width: 420px) {
          min-width: 380px;
        }
        @media (max-width: 380px) {
          min-width: 320px;
        }
      ` : ''} 
       &[class*="is-open"] {
        animation: ${animation} !important;
      }
    `
  },

  'dropdowns.select': props => {
    return `
      background-color: ${props.background || 'transparent'} !important;
      &&&:focus {
        box-shadow: 0 0 0 3px rgba(2, 103, 240, 0.08);
        border-color: rgba(0, 85, 153, 0.3);
      }
      ${props.focused ? `
        box-shadow: 0 0 0 3px rgba(2, 103, 240, 0.08) !important;
        border-color: rgba(0, 85, 153, 0.3) !important;
        border-bottom: 1px solid rgba(0, 85, 153, 0.3) !important;
      ` : ''}
      ${props.itemSelection ? `
        border: none !important;
        border-bottom: 1px solid ${custom_silver} !important;
        border-radius: 0 !important;
        padding-left: 0 !important;
        outline: none !important;
        box-shadow: none !important;
        font-size: ${props.fontSize || font_size_sm};
        color: ${props.type === 'button' ? custom_placeholder : custom_grey} !important;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-right: 20px !important;
        ${props.multiple ? `
          padding-right: 30px !important;
          .add {
            color: ${custom_blue} !important;
            position: absolute;
            right: 37px;
            font-size: 10px;
            font-weight: 600;
            text-transform: uppercase;
          }
        ` : ''}
        * {
          font-size: ${props.fontSize || font_size_sm};
          cursor: pointer;
          color: ${custom_placeholder} !important;
          background-position: center center !important;
        }
        .form-option-tag, .form-option-tag * {
          color: ${custom_black} !important; 
        }
        input {
          color: ${custom_black} !important; 
        }
        &:before {
          color: ${custom_grey};
          background-position: center center !important;
        }
        ${props.size ? `
          min-height: ${props.size}px;
          height: ${props.size}px;
          padding: 0;
          &:before {
            height: ${props.size-1}px !important;
            width: ${props.size-1}px !important;
          }
        ` : ''}    
        ${props.active ? `
          color: ${custom_black} !important; 
          * {
            color: ${custom_black} !important; 
          }
        `:''}

        ${props.focused && !props.disabled ? `
          border-bottom: 1px solid ${custom_blue} !important;
          ${props.colorOverride ? `
            color: ${custom_grey} !important;
            * {
              color: ${custom_grey} !important;
            }
          ` : ''}
        `:''}

      ` : '' /* END ITEM SELECTION*/}

      ${props.itemSelectionMini ? `
        border: none !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        height: auto !important;
        min-height: 20px !important;
        padding-right: 20px !important; 
        padding-left: 5px !important; 
        outline: none !important;
        box-shadow: none !important;
        display: flex; 
        align-items: center;
        border-radius: 0;
        color: ${custom_grey};
        font-size: ${font_size_sm};
        cursor: pointer;
        * {
          cursor: pointer;
        }
        &:before {
          color: ${custom_grey};
          height:20px !important;
          width: 20px !important;
          max-height:20px !important;
          max-width: 20px !important;
          background-position: center center !important;
        }

        &:focus:not([disabled]) {
          border-bottom: 1px solid ${custom_blue} !important;
        }

        ${props.focused && !props.disabled ? `
          border-bottom: 1px solid ${custom_blue} !important;
          ${props.colorOverride ? `
            color: ${props.colorOverride} !important;
            * {
              color: ${props.colorOverride} !important;
            }
          ` : ''}
        `:''}
      ` : ''}

      ${props.range ? `
        padding-right: 20px !important;
        &:before {
          width: 20px !important;
          max-width: 20px !important;
        }
      ` : ''}

      ${props.medium ? `
        background: red;
        min-height: 40px !important;
        font-size: ${font_size_md};
        * {
          font-size: ${font_size_md};
        }
        &.multi-select input {
          line-height: ${font_size_md} !important;
        }
        &:before {
          height:40px !important;
          width: 40px !important;
          max-height:40px !important;
          max-width: 40px !important;
        }
      ` : ''}

      ${props.uom ? `
        padding-right: 30px !important;
        &:before {
          width: 20px !important;
          max-width: 20px !important;
        }
      ` : ''}

      ${props.semibold ? `
        font-weight: ${font_weight_semibold};
      ` : ''}

      ${props.colorOverride ? `
        color: ${props.colorOverride} !important;
        * {
          color: ${props.colorOverride} !important;
        }
      ` : ''}

      ${props.cursorDisabled ? `
        caret-color: transparent;
        * {
          caret-color: transparent;
        }
      ` : ''}

      ${props.noOutline ? `
        box-shadow: none !important;
      ` : ''}

      ${props.focused && !props.disabled ? `
        border-bottom: 1px solid ${custom_blue} !important;
        ${props.colorOverride ? `
          color: ${props.colorOverride} !important;
          * {
            color: ${props.colorOverride} !important;
          }
        ` : ''}
      `:''}

      ${props.error ? `
        border-bottom: 1px solid ${color_red_400} !important;
        * {
          color: ${color_red_400} !important;
        }
        &:before {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%2368737D'%3E%3Cpath fill='none' stroke='${color_red_400}' stroke-linecap='round' d='M4 6.5l3.6 3.6c.2.2.5.2.7 0L12 6.5'/%3E%3C/svg%3E") !important;
        }
      ` : ''}

      ${props.warning ? `
        border-bottom: 1px solid ${custom_orange} !important;
        * {
          color: ${custom_orange} !important;
        }
        &:before {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%2368737D'%3E%3Cpath fill='none' stroke='${custom_orange}' stroke-linecap='round' d='M4 6.5l3.6 3.6c.2.2.5.2.7 0L12 6.5'/%3E%3C/svg%3E") !important;
        }
      ` : ''}

      ${props.pagination ? `
        border: 1px solid ${custom_light_grey} !important;
        padding-left: 15px !important;
        font-size: 11px !important;
        line-height: 22px !important;
        color: ${custom_grey} !important;
        font-weight: ${font_weight_semibold} !important;
        padding-right: 2.58571em !important;
        box-shadow: none !important;
        padding-top: 0;
        padding-bottom: 0;
        max-height: 32px;
        min-height: 32px;
        display: flex;
        align-items: center;
        padding-left: 8px !important;
        &:before {
          opacity: 0.5;
          max-height: 32px;
          font-size: 14px !important;
        }
      ` : ''}

       ${props.hideArrow ? `
        &:before {
          display: none;
        }
      ` : ''}


      ${props.filterDropdown ? `
        min-height: 30px;
        padding: 5px 10px;
        padding-right: 40px;
        &&:before {
          height: 30px;
        }
        input {
          height: 19px;
          min-height: 19px !important;
        }
        ${
          props.values ? `
            border: 1px solid #73a9f2 !important;
            background: ${custom_blue} !important;
            color: white !important;
            * {
              color: white !important;
            }
            &&:before {
              color: #fff !important; 
              background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%2368737D'%3E%3Cpath fill='none' stroke='${color_white}' stroke-linecap='round' d='M4 6.5l3.6 3.6c.2.2.5.2.7 0L12 6.5'/%3E%3C/svg%3E") !important;
            }
          ` : ''
        }
      ` : ''}




      ${props.tableSort ? `
        min-height: 36px;
        padding: 5px 10px;
        padding-right: 40px;
        .select-input {
          line-height: 24px;
          * {
            line-height: 24px;
          }
        }
        &&:before {
          height: 36px;
        }
        input {
          height: 19px;
          min-height: 19px !important;
        }
        ${
          props.values ? `
            background: ${custom_blue} !important;
            color: white !important;
            * {
              color: white !important;
            }
            &&:before {
              color: #fff !important; 
              background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%2368737D'%3E%3Cpath fill='none' stroke='${color_white}' stroke-linecap='round' d='M4 6.5l3.6 3.6c.2.2.5.2.7 0L12 6.5'/%3E%3C/svg%3E") !important;
            }
          ` : ''
        }
      ` : ''}

    `;
  },

  'dropdowns.item': props => {
    return `
      ${props.itemsSmall ? `
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        font-size: 12px !important;
      ` : ''}
      ${!props.disabled && !props.clear ? `
        &:hover {
          background-color: ${custom_blue} !important;
          color: ${color_white};
          * {
            color: ${color_white};
          }
          &:before {
            background: no-repeat 50%/16px url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%231F73B7'%3E%3Cpath fill='none' stroke='${color_white}' stroke-linecap='round' stroke-linejoin='round' d='M1 9l4 4L15 3'/%3E%3C/svg%3E") !important;
          }
        }
      ` : ''}

      ${props.hoverColorLight ? `
        &:hover {
          background-color: ${custom_light_grey} !important;
          color: ${custom_blue};
        }
      ` : ''}
      ${props.clear ? `
        &:hover {
          background-color: ${custom_light_grey} !important;
        }
      ` : ''}

    `;

  },
};

export default DropDown;
