import React from 'react';
import styled from 'styled-components/macro';
import {variables} from 'theme/variables';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



const {
  custom_black,  
  spacing_sm,
  spacing_xxs
} = variables;

const IconWrapper = styled.span`
  box-shadow: none;
  display: block;
  outline: none;
  backface-visibility: visible;
  transform: translate3d(0,0,0);
  font-size: ${props => props.fontSize ? props.fontSize : '18px'};
  color: ${props => props.color ? props.color : custom_black};
  cursor: ${props => props.pointer || props.button ? 'pointer' : 'default'};
  transition: all 0.3s ease;
  transform: ${props => props.flipped ? 'rotate(180deg)' : 'rotate(0deg)'};
  &:hover {
    color: ${props => props.hoverColor ? props.hoverColor : props.color ? props.color : custom_black};
  }
  &:before {
    backface-visibility: visible;
    transform: translate3d(0,0,0);
    cursor: ${props => props.pointer || props.button ? 'pointer' : 'default'};
  }

  ${props => props.buttonStyle ? `
    width: 40px;
    height: 40px;
    ${props.small ? `
      width: 30px;
      height: 30px;
    ` : ''}
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 1;
    &:before {
      cursor: pointer;
    }
  ` : ''}


  ${props => props.fadeOnHover || props.buttonStyle ? `
    opacity: 1;
    transition: opacity 0.3s ease, color 0.3s ease;
    &:before {
      transition: transform 0.3s ease, color 0.3s ease;
      transform: scale(1);
    }
    &:hover {
      opacity: 0.8;
    }
    &:active {
      &:before {
        transform: scale(0.95);
      }
    }
  ` : ''}

  ${props => props.fadeOnHover ? `
    transition: opacity 0.3s ease;
    opacity: 1;
    &:hover {
      opacity: 0.5;
    }
  ` : ''}

  ${props => props.alignCenter ? `
    display: flex;
    align-items: center;
  ` : ''}

  ${props => props.spacingRight ? `
    margin-right: ${spacing_sm};
  ` : ''}

  ${props => props.spacingLeft ? `
    margin-left: ${spacing_sm};
  ` : ''}

  ${props => props.spacingRightSm ? `
    margin-right: ${spacing_xxs};
  ` : ''}

  ${props => props.spacingLeftSm ? `
    margin-left: ${spacing_xxs};
  ` : ''}

  ${props => props.justifyEnd ? `
    justify-content: flex-end;
  ` : ''}

  ${props => props.center ? `
    display: flex;
    justify-content: center;
  ` : ''}

  ${props => props.inline ? `
    display: inline;
    vertical-align: middle;
    padding-right: 7px;
    line-height: inherit;
  ` : ''}
`;
 
const Icon = (props) => {  
  return (
    props.fontAwesome ?
    <IconWrapper {...props}>
      {/*<FontAwesomeIcon style={!props.fontSize ? {} : {fontSize: props.fontSize}} icon={['fad', props.icon]} color={props.color} />*/}
    </IconWrapper> :
    <IconWrapper className={`${props.icon} ${props.className}`} {...props} />
  )
}


export default Icon
