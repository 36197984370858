import React, {useEffect, useState} from 'react';
import Spinner from 'components/Spinner/Spinner';
import history from 'historyContainer';
import {get} from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_ME } from 'state/authentication/gql';
import {refreshSession, teardownSession, setupResource} from 'state/authentication/actions';

const AuthenticationWrapper = (props) => {
  const [render, setRender] = useState(0);
  const [account, setAccount] = useState(0);
  const { loading, error, data } = useQuery(GET_ME);

  const userId = get(data, 'Me.id');
  console.log(error, userId)
  useEffect(() => {
    if (get(data, 'Me')) {
      if (userId) {
        refreshSession(get(data, 'Me'));
        setRender(true);
        setAccount(get(data, 'Me'))
        setupResource(get(data, 'Me.resources'));

      } else {
        teardownSession();
        setRender(true)
      }
    } else if(error) {
      teardownSession();
      setRender(true);
    }
  }, [userId, error]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {
        loading || (!get(data, 'Me') && !error) || !render ?
        <Spinner /> :
        props.children({canRender: account, account})
      }
    </div>
  )
};

export default AuthenticationWrapper;