
export const linkArray = [
  {link: "/c/equipment/mri", title: "Mri Machines"},
  {link: "/c/equipment/ct", title: "CT Machines"},
  {link: "/c/equipment/pet-ct", title: "PET / CT Machines"},
  // {link: "/c/equipment/ultrasound", title: "Ultrasound Machines"},
  {link: "/c/equipment", title: "All Machines"}
];



export const SERVICES = [
  {
    type: 'mri',
    title: "MRI Machines",
    description: "MRT offers a large selection of reconditioned MRI's including low field open, 1.5T, 3T, and Mobile MRIs. MRIs can create more detailed images of organs, soft tissues, spinal cords and brains than CT units.",
    services: [
      {
        title: "Low Field Open MRI",
        description: "The low-field open MRI uses a magnet top and bottom and is open on all four sides tailoring to claustrophobic and larger patient demographic. They are also widely used in Veterinary practices.",
        link: "",
        cta: "",
        image: "https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1599765368/AdobeStock_143959792_copy_z73dcf.jpg",
        type: "mri",
        slug: "open-mri",
        models: ['Open Mri', '0.3T']
      },
      {
        title: "1.5T MRI",
        description: "The 1.5T MRI units are a staple in today’s market and the most widely used magnet strength. They produce clearer, faster images than the low-field open units and would be better than a low-field open for breast, coronary and G.I. tracts.",
        link: "",
        cta: "",
        image: "https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1599764267/istockphoto-946845536-612x612_bbphnn.jpg",
        type: "mri",
        slug: "1.5t-mri",
        models: ['1.5T']
      },
      {
        title: "3T MRI",
        description: "3T machines have increased contrast detection than 1.5T units, producing higher resolution images, can be significantly faster in terms of scan time and are ideal for orthopedic, neurologic and vascular imaging. These machines are also found in many research centers due to their extraordinary imaging abilities.",
        link: "",
        cta: "",
        image: "https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1599764018/istockphoto-906612074-612x612_dmaifw.jpg",
        type: "mri",
        slug: "3t-mri",
        models: ['3T']
      },
      {
        title: "Mobile MRI",
        description: "Mobile MRI units can be utilized to provide your facility with flexibility. It contains your costs, can be used temporarily, service a wider region of patients and allows a facility to test the market before committing fully to a fixed system.",
        link: "",
        cta: "",
        image: "https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1601511045/Screen_Shot_2020-09-30_at_7.10.30_PM_tbnsvl.png",
        type: "mri",
        slug: "mobile-mri",
        models: ['Mobile MRI']
      },
    ]
  },


  {
    title: "CT Scanners",
    type: 'ct',
    description: "MRT offers a large selection of reconditioned CT machines ranging from 4 to 320 slices. CT images are good for scanning injuries from trauma, abdominal pain, staging cancer, imaging bone, soft tissue differentiation and blood vessels. CT units rarely cause discomfort or stress for patients.",
    services: [
      {
        title: "4, 6, 8 Slice CT Machine",
        name: "Small CT",
        description: "The 4, 6, 8 Slice CT are typically used in veterinary work, smaller or low income markets",
        link: "",
        cta: "",
        image: "https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1599852715/istockphoto-526113903-612x612_bm5bnx.jpg",
        type: "ct",
        slug: "4-6-8-slice-ct",
        models: ['4 Slice', '6 Slice', '8 Slice']
      },
      {
        title: "16 Slice CT Machine",
        name: "16 Slice CT",
        description: "The most widely used CT in the market, the 16 slice CT is your “bread and butter” scanner capable of handling the workload of most practices.",
        link: "",
        cta: "",
        image: "https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1599852715/istockphoto-1153271040-612x612_meiunh.jpg",
        type: "ct",
        slug: "16-slice-ct",
        models: ['16 Slice']
      },
      {
        title: "64 Slice CT Machine ",
        name: "64 Slice CT",
        description: "Twice the speed and image quality as the 16 slice, the 64 slice CT is often recommended for practices doing heavy cardiac.",
        link: "",
        cta: "",
        image: "https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1599852715/istockphoto-1074166156-612x612_wrtgwh.jpg",
        type: "ct",
        slug: "64-slice-ct",
        models: ['64 Slice']
      },
      {
        title: "128, 256, 320 Slice CT Machine",
        name: "Large CT",
        description: "These incredibly fast units are typically reserved for advanced imaging and research facilities.",
        link: "",
        cta: "",
        image: "https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1599852715/istockphoto-881676836-612x612_dmerig.jpg",
        type: "ct",
        slug: "128-256-320-slice-ct",
        models: ['128 Slice', '256 Slice', '320 Slice']
      },
      {
        title: "Mobile CT Unit",
        description: "Mobile CT units can be utilized to provide your facility with flexibility. It contains your costs, can be used temporarily, service a wider region of patients and allows a facility to test the market before committing fully to a fixed system.",
        link: "",
        cta: "",
        image: "https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1601511045/Screen_Shot_2020-09-30_at_7.10.30_PM_tbnsvl.png",
        type: "ct",
        slug: "mobile-ct",
        models: ['Mobile CT']
      },
    ]
  },


  {
    title: "PET / CT Scanners",
    type: 'pet-ct',
    description: "Positron Emission Tomography is a type of scanning  can be more “sensitive” than other imaging and  help doctors see how tissues and organs are functioning. They are often used to learn the stage of cancer and other diseases once detected by using a radioactive tracer and comprehending the spread or activity.",
    services: [
      {
        title: "PET Machine",
        description: "Most widely used are 16, 64 and 128 slice units.",
        link: "",
        cta: "",
        image: "https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1599853415/istockphoto-531930387-612x612_tnfdps.jpg",
        type: "pet-ct",
        slug: "all-pet-ct",
        models: ['Pet']
      },
      
    ]
  },
  // {
  //   title: "Ultrasound Machines",
  //   type: 'ultrasound',
  //   description: "An ultrasound scan uses high-frequency sound waves to create images of the inside of the body and is generally safer as it uses sound waves instead of radiation. Ultrasound scans are used to evaluate fetal development, and they can detect problems in the liver, heart, kidney, or abdomen.",
  //   services: [
  //     {
  //       title: "Ultrasound Machines",
  //       description: "An ultrasound scan uses high-frequency sound waves to create images.",
  //       link: "",
  //       cta: "",
  //       image: "https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1599854629/istockphoto-1154784567-612x612_mn3pnm.jpg",
  //       type: "ultrasound",
  //       slug: "ultrasound",
  //       models: ['Ultrasound']
  //     },
      
  //   ]
  // }
]