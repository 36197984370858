import Grid from './Grid';
import TextField from './TextField';
import Typography from './Typography';
import Button from './Button';
import Menu from './Menu';
import Tag from './Tag';
import ToolTip from './ToolTip';
import Table from './Table';
import Tab from './Tab';
import Select from './Select';
import Dropdown from './Dropdown';
import Checkbox from './Checkbox';
import Notifications from './Notifications';
import RangeField from './RangeField';
const theme = {
  ...Grid,
  ...TextField,
  ...Typography,
  ...Button,
  ...Menu,
  ...Tag,
  ...ToolTip,
  ...Table,
  ...Tab,
  ...Select,
  ...Dropdown,
  ...Checkbox,
  ...Notifications,
  ...RangeField
};

export default theme;