import styled from 'styled-components/macro';
import {variables} from 'theme/variables';
const {
  custom_border_grey
} = variables;

export const MenuItem = styled.li`
  width: 100%;
  a {
    display: block;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid ${custom_border_grey};
  }
`;



export const Close = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;
