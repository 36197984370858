import {canUseDOM} from 'exenv';
let url;
let sentryUrl = "";
let environment;
let segmentKey = '';
let intercomAppId = '';
let mapboxKey = ''
let cloudinaryId = 'hbq7zoknx'

if (canUseDOM && process.env.GRAPH_URL) {
  url = process.env.GRAPH_URL;
  intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;
} else if(canUseDOM) {
  url = window.__GRAPH_URL__ || "http://localhost:3000/graphql";
}

if (canUseDOM && window.__ENVIRONMENT__) {
  environment = window.__ENVIRONMENT__;
} else if(canUseDOM) {
  environment = 'dev'
}

if (canUseDOM && window.__SENTRY_URL__) {
  sentryUrl = window.__SENTRY_URL__;
}

if (canUseDOM && window.__SEGMENT_KEY__) {
  segmentKey = window.__SEGMENT_KEY__;
}

if (canUseDOM && window.__MAPBOX_KEY__) {
  mapboxKey = window.__MAPBOX_KEY__;
}


export const CLOUDINARY_ID = cloudinaryId;
export const BASE = url;
export const SENTRY_URL = sentryUrl;
export const ENVIRONMENT = environment;
export const INTERCOM_APP_ID = intercomAppId;
export const SEGMENT_KEY = segmentKey;
export const MAPBOX_KEY = mapboxKey;