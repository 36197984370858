import { gql } from '@apollo/client';

export const CREATE_ACCOUNT = gql`
  mutation Register($first_name: String!, $last_name: String!, $email: String!, $password: String!) {
    Register(first_name: $first_name, last_name: $last_name, email: $email, password: $password)
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    Login(email: $email, password: $password) {
      user {
         id
         first_name
         last_name
         email
         phone
         image
         bio
         avatar
         stripe_customer_id
         delete_date
         facebook_credentials
         facebook_user_id
         timezone
         reset_password_token
         reset_password_expires
         created_at
         updated_at
      }
      token
    }
  }
`;

export const GET_ME = gql`
  query getMe {
    Me {
      id
      first_name
      last_name
      email
      phone
      image
      bio
      avatar
      delete_date
      facebook_credentials
      facebook_user_id
      timezone
      created_at
      updated_at
      resources {
        id,
        resource_name
      }
    }
  }
`;