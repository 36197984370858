import styled from 'styled-components/macro';
import { Button as ZendeskButton } from '@zendeskgarden/react-buttons';
import Flex from 'styled-flex-component';
import {variables} from './variables';
const {
  spacing_sm,
  spacing_xs,
  spacing_md,
  spacing_xxxl,
  spacing_xl,
  custom_blue,
  custom_grey,
  custom_silver,
  app_page_padding,
  media_sm,
  media_md,
  app_page_padding_medium,
  app_page_padding_small,
  border_radius,
  color_white,
  font_weight_semibold,
  custom_light_grey,
  custom_sky_blue
} = variables


export const Divider = styled('div')`
  height: 100%;
  display: flex;
  min-height: ${props => props.minHeight || `15px`};
  width: 1px;  
  margin: ${props => props.margin || `0 ${spacing_sm}`};
  padding: ${props => props.padding || `0`};
  position: relative;
  font-size: 10px;
  &:after {
    content: "";
    position: absolute;
    top: ${props => props.top || 0};
    bottom: ${props => props.bottom || 0};
    right: 0;
    left: 0;
    margin: 0;
    width: 1px;
    background: ${props => props.background || custom_grey};
  }
  ${props => props.horizontal ? `
    height: 1px;
    min-height: 1px;
    width: 100%;
    margin: 0;
    &:after {
      content: "";
      position: absolute;
      top: auto;
      bottom: auto;
      right: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: ${props.background || custom_light_grey};
    }
    ${props.section ? `
      padding: ${spacing_xxxl}
    ` : ''}
  ` : ''}
`


export const DividerContainer = styled(Flex)`
  position: relative;
  width: 100%;
  padding: ${props => props.last ? `${spacing_md} 0 ${0}` : props.first ? `${0} 0 ${spacing_md}` : `${spacing_md} 0 ${spacing_md}`};
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: auto;
    right: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: ${props => props.background || custom_light_grey};
    display: ${props => props.first ? 'none' : `block`};
  }
  &:before {
    content: "";
    position: absolute;
    top: auto;
    bottom: 0;
    right: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: ${props => props.background || custom_light_grey};
    display: ${props => props.nested || props.last ? 'none' : 'block'};
  }
`

export const Dot = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    max-width: ${props => props.size || '6px'};
    max-height: ${props => props.size || '6px'};
    min-width: ${props => props.size || '6px'};
    min-height: ${props => props.size || '6px'};
    margin: ${props => props.margin || `0 ${spacing_xs} 0 0`};
    border-radius: 100%;
    background: ${props => props.transparent ? 'transparent' : (props.background || custom_blue)};
  }
`

export const Card = styled('div')`
  padding: ${spacing_xl};
  background: ${color_white};
  box-shadow: 0px 2px 13px rgba(68, 89, 109, 0.08);
  ${props => props.active ? `
    background: ${custom_blue}
    box-shadow: ${props.boxShadow ? props.boxShadow : '0px 9px 15px rgba(68,89,109,0.3)'};
  ` : ''}

`

export const BulletGroup = styled(Flex)`
  margin-top: ${spacing_md}
`

export const Bullet = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 30px;
  height: 30px;
  color: ${color_white};
  margin-right: ${spacing_sm};
  border-radius: ${border_radius};
  background: ${props => props.transparent ? 'transparent' : (props.background || custom_blue)};
  font-weight: ${font_weight_semibold};
  ${props => props.inverted ? `
    color: ${props.transparent ? 'transparent' : (props.background || custom_blue)};
    background: ${color_white};
  ` : ''}
`

export const ScrollWrapper = styled('div')`
  position: relative;
`;

export const ScrollContainer = styled('div')`
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  ${props => props.horizontal ? `
    overflow-x: auto;
  ` : `
    position: absolute;
    top: ${props => props.top || '0px'}; 
    bottom: ${props => props.bottom || '0px'}; 
    right: ${props => props.right || '0px'}; ;
    left: ${props => props.left || '0px'};
    overflow: auto;
  `};
`;

export const PageCard = styled('div')`
  box-shadow: ${props => props.boxShadow ? props.boxShadow : '0px 10px 50px rgba(68, 89, 109, 0.1)'};
  padding: ${props => props.mobilePadding || app_page_padding_small};
  @media ${media_sm} {
   padding: ${props => props.tabletPadding || app_page_padding_medium};
   padding-bottom: ${spacing_xxxl};
   margin-bottom: ${spacing_xxxl};
  }
  @media ${media_md} {
    padding: ${props => props.padding || app_page_padding};
    padding-bottom: ${spacing_xxxl};
    margin-bottom: ${spacing_xxxl};
  }
`;

export const LBracket = styled('div')`
  position: absolute;
  width: ${props => props.width || '12px'}; 
  height: ${props => props.height || '15px'}; 
  border-left: 2px solid ${custom_silver};
  border-bottom: 2px solid ${custom_silver};
  ${props => props.table ? `
    margin-left: 4px;
    margin-top: -6px;
  ` : ''}
`;

export const Ellipsis = styled('div')`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Avatar = styled('div')`
  background-image: url("${props => props.src || 'https://therminic2018.eu/wp-content/uploads/2018/07/dummy-avatar.jpg'}");
  background-size: ${props => props.size || 'cover'};
  border-radius: 100%;
  width: ${props => props.scale ? props.scale :  props.large ? '80px' : '40px'};
  height: ${props => props.scale ? props.scale :  props.large ? '80px' : '40px'};
  background-position: ${props => props.position || 'center left'};
  background-repeat: no-repeat;
  cursor: pointer;
  ${props => props.center ? `
    margin: 0 auto;
  ` : ''}
`;


export const TableOverflowWrapper = styled('div')`
  max-width: 100%; 
  min-width: ${props => props.minWidth};
`;

export const TableOverflowScroll = styled('div')`
  overflow-x: auto;
  overflow-scrolling: touch;
`;

export const DivButton = styled('button')`
  border: 1px solid ${custom_light_grey};
  transition: all 0.3s ease;
  * {
    transition: all 0.3s ease;
  }
  &:hover {
    background: ${custom_sky_blue};
  }
  &:active, &:focus {
    outline: none;
    border: 1px solid #cfcfd0;
  }
  ${props => props.active ? `
   border: 1px solid ${custom_blue} !important;
  ` : ''}
`;

export const IconOutlineButton = styled(Flex)`
  max-width: 23px; 
  margin-right: 10px; 
  background: ${custom_blue};
  padding: 7px; 
  border-radius: 100px;
  cursor: pointer;

`


export const ContactButton = styled(ZendeskButton)`
  background: #fff;
  margin-right: 10px;
  padding: 0 7px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  &&:hover {
    background-color: #fff;
  }
  @media ${media_sm} {
    padding: 0 15px;
  }
`


export const NavList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const NavItem = styled.div`
  padding: 0 20px 0 0;
  margin: 7px 0;
  a {
    font-size: 14px !important;
    color: ${custom_blue} !important;
    padding: 6px 10px;
    text-transform: uppercase;
    border: 1px solid rgba(52, 100, 230, 0.14);
    display: block;
    border-radius: 2px;
    transition: all 0.3s ease;
    &:hover {
      background-color: rgba(52, 100, 230, 0.14);
      color: ${custom_blue} !important;
    }
    &.active-content-link {
      background: ${custom_blue} !important;
      color: #fff !important;
    }
  }
`
