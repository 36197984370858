import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import {BASE} from 'constants.js';

const uri =  BASE;
const httpLink = createHttpLink({
  uri,
});

const authLink = setContext((_, {headers}) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  const options = {
    headers: {
      ...headers,
    }
  };

  if (token) {
    options.headers.Authorization = `${token}`;
  }
  return options;
});


const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
});


export const client = apolloClient;