import {identify} from 'utility/analytics';
import * as Sentry from '@sentry/browser';

export function identifyUser(session) {
  identify(session.user_id, {"first_name": session.first_name, "last_name": session.last_name, "email": session.email, "user_id": session.user_id});
  Sentry.configureScope(function(scope) {
    scope.setUser({"email": session.email, "user_id": session.user_id});
  });

  if (window.Intercom) {
    // window.Intercom("boot", {
    //   app_id: window.__INTERCOM_APP_ID__ || INTERCOM_APP_ID,
    //   user_id: session.user_id,
    //   email: session.email
    // });
  }
}