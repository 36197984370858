import React, {useEffect} from 'react'
import AuthenticationWrapper from 'components/AuthenticationWrapper/AuthenticationWrapper';
import { startCase } from 'lodash'
import Spinner from 'components/Spinner/Spinner';
import Loadable from 'react-loadable';
import authOnEnter from 'utility/authOnEnter';
import {isFacilitator, isAdmin} from 'utility/hasAccount';
import {Route, Redirect} from 'react-router';

export const hardRedirectRoute = (route) => {  
  return (props) => {
    useEffect(() => {
      window.location = route
    }, []);
    return (
      <div/>
    )
  };
};

export const requireAuth = (Component) => {  
  return (props) => {
    const isAuthenticated = authOnEnter();
    return (
      isAuthenticated ?
      <Component {...props}/> :
      <RedirectWithStatus
        status={302}
        to={{
          pathname: `/`,
          state: {from: props.location, next: props.location.pathname}
      }}/>
    )
  };
};

export const requireAuthWithoutRole = (Component) => {  
  return (props) => {
    return (
      authOnEnter() ?
      <Component {...props}/> :
      <RedirectWithStatus
        status={302}
        to={{
          pathname: '/',
          state: {from: props.location}
      }}/>
    )
  };
};

export const requireFacilitatorOrAdmin = (Component) => {  
  return (props) => {
    return (
      <AuthenticationWrapper>
        {({canRender, account}) => {
        
          return (
            canRender ?
              <Component {...props} account={account}/> :
              <RedirectWithStatus
                status={302}
                to={{
                  pathname: '/',
                  state: {from: props.location}
              }}/>
          )
        
        }}
      </AuthenticationWrapper>
    )
  };
};


export const requireFacilitator = (Component) => {  
  return (props) => {
    return (
      authOnEnter() && isFacilitator() ?
      <Component {...props}/> :
      <RedirectWithStatus
        status={302}
        to={{
          pathname: '/',
          state: {from: props.location}
      }}/>
    )
  };
};

export const requireAdmin = (Component) => {  
  return (props) => {
    return (
      authOnEnter() && isAdmin() ?
      <Component {...props}/> :
      <RedirectWithStatus
        status={302}
        to={{
          pathname: '/',
          state: {from: props.location}
      }}/>
    )
  };
};

const RedirectWithStatus = ({from, to, status}) => (
  <Route
    render={({staticContext}) => {
      if (staticContext) {
        staticContext.status = status;
      }
      return <Redirect from={from} to={to}/>;
    }}/>
);

const error404 = (Component) => {
  return (props) => {
    return (
      <Route
        render={({staticContext}) => {
          if (staticContext) {
            staticContext.missed = true;
            staticContext.status = 404;
          }
          return <Component {...props}/>;
        }}/>
    );
  };
};

const PageLoader = (props) => {
  return (
    <div style={{minHeight: '100vh', display: 'flex', alignItems: 'center'}}>
      <Spinner />
    </div>
  );
};

const AsyncDashboard = Loadable({
    loader: () => import(/* webpackChunkName: "dashboardOne" */ './routes/Dashboard/Dashboard'),
    loading: () => <PageLoader />,
    modules: ['Dashboard'],
});

export const AsyncPageError = Loadable({
    loader: () => import(/* webpackChunkName: "errorPage" */ './routes/Error/Error'),
    loading: () => <PageLoader />,
    modules: ['Error'],
});

export const AsyncPageDisclaimer = Loadable({
  loader: () => import(/* webpackChunkName: "DisclaimerPage" */ './routes/Disclaimer/Disclaimer'),
  loading: () => <PageLoader />,
  modules: ['Disclaimer'],
});

const AsyncCantLogin = Loadable({
    loader: () => import(/* webpackChunkName: "CantLoginChunk" */ './routes/CantLogin/CantLogin'),
    loading: () => <PageLoader />,
    modules: ['AsyncCantLoginChunk'],
});

const AsyncConfirmEmail = Loadable({
    loader: () => import(/* webpackChunkName: "ConfirmEmailChunk" */ './routes/ConfirmEmail/ConfirmEmail'),
    loading: () => <PageLoader />,
    modules: ['AsyncConfirmEmailChunk'],
});

const AsyncLogin = Loadable({
    loader: () => import(/* webpackChunkName: "LoginChunk" */ './routes/Login/Login'),
    loading: () => <PageLoader />,
    modules: ['AsyncLoginChunk'],
});


const AsyncResetPassword = Loadable({
    loader: () => import(/* webpackChunkName: "ResetPasswordChunk" */ './routes/ResetPassword/ResetPassword'),
    loading: () => <PageLoader />,
    modules: ['AsyncResetPasswordChunk'],
});


const AsyncBlogList = Loadable({
    loader: () => import(/* webpackChunkName: "BlogListChunk" */ './routes/BlogList/BlogList'),
    loading: () => <PageLoader />,
    modules: ['BlogListChunk'],
});

const AsyncPageBlogPostContent = Loadable({
    loader: () => import(/* webpackChunkName: "BlogPostContentChunk" */ './routes/BlogPostContent/BlogPostContent'),
    loading: () => <PageLoader />,
    modules: ['BlogPostContentChunk'],
});

const AsyncPageCategoriesWrapper = Loadable({
    loader: () => import(/* webpackChunkName: "CategoriesChunk2" */ './routes/Categories/Categories'),
    loading: () => <PageLoader />,
    modules: ['CategoriesChunk2'],
});


const AsyncPageCategoryDetails = Loadable({
    loader: () => import(/* webpackChunkName: "CategoriesChunk" */ './routes/Categories/routes/CategoryDetails/CategoryDetails'),
    loading: () => <PageLoader />,
    modules: ['CategoriesChunk'],
});


const AsyncListingPages = Loadable({
    loader: () => import(/* webpackChunkName: "ListingsChunk" */ './routes/Products/Listings'),
    loading: () => <PageLoader />,
    modules: ['ListingsChunk'],
});


const AsyncPageOpportunities = Loadable({
    loader: () => import(/* webpackChunkName: "authPage" */ './routes/Opportunities/Opportunities'),
    loading: () => <PageLoader />,
    modules: ['Opportunities'],
});

const AsyncPageListingsManagement = Loadable({
    loader: () => import(/* webpackChunkName: "authPage" */ './routes/ListingsManagement/ListingsManagement'),
    loading: () => <PageLoader />,
    modules: ['ListingsManagement'],
});

const AsyncPageBlogEditor = Loadable({
    loader: () => import(/* webpackChunkName: "authPage" */ './routes/BlogEditor/BlogEditor'),
    loading: () => <PageLoader />,
    modules: ['BlogEditor'],
});


const AsyncPageCreateAccount = Loadable({
    loader: () => import(/* webpackChunkName: "CreateAccountChunk" */ './routes/CreateAccount/CreateAccount'),
    loading: () => <PageLoader />,
    modules: ['CreateAccount'],
});



const AsyncPageLanding = Loadable({
    loader: () => import(/* webpackChunkName: "LandingChunk" */ './routes/Landing/Landing'),
    loading: () => <PageLoader />,
    modules: ['LandingChunk'],
});


const AsyncPageServices = Loadable({
    loader: () => import(/* webpackChunkName: "ServicesChunk" */ './routes/Services/Services'),
    loading: () => <PageLoader />,
    modules: ['ServicesChunk'],
});


const AsyncPageCompany = Loadable({
    loader: () => import(/* webpackChunkName: "Company" */ './routes/Company/Company'),
    loading: () => <PageLoader />,
    modules: ['Company'],
});

const AsyncPageListings = Loadable({
    loader: () => import(/* webpackChunkName: "Listings" */ './routes/Listings/Listings'),
    loading: () => <PageLoader />,
    modules: ['Listings'],
});


const AsyncPageCompanyWhyMrt = Loadable({
    loader: () => import(/* webpackChunkName: "CompanyWhyMrt" */ './routes/CompanyWhyMrt/CompanyWhyMrt'),
    loading: () => <PageLoader />,
    modules: ['CompanyWhyMrt'],
});




const AsyncPageContact = Loadable({
    loader: () => import(/* webpackChunkName: "ContactChunk" */ './routes/Contact/Contact'),
    loading: () => <PageLoader />,
    modules: ['ContactChunk'],
});



//ADMIN PAGES

const AsyncAdminPanel = Loadable({
    loader: () => import(/* webpackChunkName: "dashboardOne" */ './routes/AdminPanel/AdminPanel'),
    loading: () => <PageLoader />,
    modules: ['AdminPanel'],
});


//SETTINGS PAGES\
const AsyncSettingsPanel = Loadable({
    loader: () => import(/* webpackChunkName: "settingsPage" */ './routes/SettingsPanel/SettingsPanel'),
    loading: () => <PageLoader />,
    modules: ['SettingsPanel'],
});


const AsyncEmailPreferences = Loadable({
    loader: () => import(/* webpackChunkName: "settingsPage" */ './routes/EmailPreferences/EmailPreferences'),
    loading: () => <PageLoader />,
    modules: ['EmailPreferences'],
});



// SERVICES

const AsyncPageCtProcurement = Loadable({
    loader: () => import(/* webpackChunkName: "CtProcurement" */ './routes/Services/routes/CtProcurement/CtProcurement'),
    loading: () => <PageLoader />,
    modules: ['CtProcurement'],
});

const AsyncPageMriProcurement = Loadable({
    loader: () => import(/* webpackChunkName: "MriProcurement" */ './routes/Services/routes/MriProcurement/MriProcurement'),
    loading: () => <PageLoader />,
    modules: ['MriProcurement'],
});

const AsyncPageDeliverRigging = Loadable({
    loader: () => import(/* webpackChunkName: "DeliveryRigging" */ './routes/Services/routes/DeliveryRigging/DeliveryRigging'),
    loading: () => <PageLoader />,
    modules: ['DeliveryRigging'],
});

const AsyncPageInstallDeinstall = Loadable({
    loader: () => import(/* webpackChunkName: "InstallDeinstall" */ './routes/Services/routes/InstallDeinstall/InstallDeinstall'),
    loading: () => <PageLoader />,
    modules: ['InstallDeinstall'],
});

const AsyncPageServicingMaintanance = Loadable({
    loader: () => import(/* webpackChunkName: "ServicingManintenance" */ './routes/Services/routes/ServicingManintenance/ServicingManintenance'),
    loading: () => <PageLoader />,
    modules: ['ServicingManintenance'],
});

const AsyncPageTestingAndRepairs = Loadable({
    loader: () => import(/* webpackChunkName: "TestingAndRepairs" */ './routes/Services/routes/TestingAndRepairs/TestingAndRepairs'),
    loading: () => <PageLoader />,
    modules: ['TestingAndRepairs'],
});

const AsyncPageRfShielding = Loadable({
    loader: () => import(/* webpackChunkName: "RfShielding" */ './routes/Services/routes/RfShielding/RfShielding'),
    loading: () => <PageLoader />,
    modules: ['RfShielding'],
});

const AsyncPageMagneticShielding = Loadable({
    loader: () => import(/* webpackChunkName: "MagneticShielding" */ './routes/Services/routes/MagneticShielding/MagneticShielding'),
    loading: () => <PageLoader />,
    modules: ['MagneticShielding'],
});

const AsyncPageStorageService = Loadable({
    loader: () => import(/* webpackChunkName: "Storage" */ './routes/Services/routes/Storage/Storage'),
    loading: () => <PageLoader />,
    modules: ['Storage'],
});




const Routes = [
  {
    path: ['/landing', '/', '/m/*', '/landing/*', '/index.html', 'index'],
    exact: true,
    component: AsyncPageLanding,
    loadData: () => {
      return {};
    }
  },
  {
    path: "/l/:slug/:id",
    exact: true,
    component: AsyncListingPages,
    loadData: () => {}
  },
  {
    path: "/tags/:tag",
    exact: true,
    component: AsyncBlogList,
    breadcrumb: (s) => {
      return (<span>{startCase(s.match.path)}</span>);
    },
  },
  {
    path: ["/blog", "/technical-content", "/industry-news", "/blog", "/equipment-news"],
    exact: true,
    component: AsyncBlogList,
    breadcrumb: (s) => {
      return (<span>{startCase(s.match.path)}</span>);
    },
    loadData: () => {
      return {};
    }
  },
  {
    path: ["/blog/:slug/:id", "/technical-content/:slug/:id", "/industry-news/:slug/:id", "/blog/:slug/:id", "/equipment-news/:slug/:id"],
    exact: true,
    component: AsyncPageBlogPostContent,
    breadcrumb: (s) => {
      return (<span>{startCase(s.match.params.slug)}</span>);
    },
    loadData: () => {}
  },

  {
    path: "/register-2",
    exact: true,
    component: AsyncPageCreateAccount
  },
  // {
  //   path: "/account-created",
  //   exact: true,
  //   component: AsyncAccountCreated,
  //   loadData: () => {
  //     return {};
  //   }
  // },
  
  {
    path: "/sign-in",
    exact: true,
    component: AsyncLogin,
    loadData: () => {
      return {};
    }
  },
  {
    path: "/account/confirmation",
    exact: true,
    component: AsyncConfirmEmail,
  },
  {
    path: "/recover-password",
    exact: true,
    component: AsyncCantLogin,
    loadData: () => {
      return {};
    }
  },
  {
    path: "/account/reset",
    exact: true,
    component: AsyncResetPassword,
    loadData: () => {
      return {};
    }
  },


  {
    path: ["/c", "/c/*", "/c/*/*", "/c/*/*/*", '/equipment', '/equipment.html'],
    component: AsyncPageCategoriesWrapper,
    routes: [
      {
        path: [
          '/c/:catType/:catGroupSlug/:catSlug',
          '/c/:catType/:catGroupSlug',
          '*'
        ],
        exact: true,
        component: AsyncPageCategoryDetails,
        loadData: () => {}
      }
    ]
  },
  
  {
    path: "/listings",
    exact: true,
    component: AsyncPageListings,
    loadData: () => {
      return {};
    }
  },

  {
    path: ["/company", "/about.html", "/about"],
    exact: true,
    component: AsyncPageCompany,
    loadData: () => {
      return {};
    }
  },

 
  {
    path: "/why-mrt",
    exact: true,
    component: AsyncPageCompanyWhyMrt,
    loadData: () => {
      return {};
    }
  },

  {
    path: "/services/procurement/ct",
    component: AsyncPageCtProcurement,
    exact: true
  },
  {
    path: "/services/procurement/mri",
    component: AsyncPageMriProcurement,
    exact: true
  },
  {
    path: ["/services/rigging-deliver"],
    component: AsyncPageDeliverRigging,
    exact: true
  },
  {
    path: ["/services/install-deinstall"],
    component: AsyncPageInstallDeinstall,
    exact: true
  },
  {
    path: ["/services/maintenance"],
    component: AsyncPageServicingMaintanance,
    exact: true
  },
  {
    path: ["/services/testing-and-repairs", "/service-repairs.html", "/service-repairs"],
    component: AsyncPageTestingAndRepairs,
    exact: true
  },
  {
    path: ["/services/rf-shielding", "/rf-shielding.html", "/rf-shielding"],
    component: AsyncPageRfShielding,
    exact: true
  },
  {
    path: ["/services/magnetic-shielding", "/magnetic-shielding.html", "/magnetic-shielding",],
    component: AsyncPageMagneticShielding,
    exact: true
  },
  {
    path: ["/services/storage"],
    component: AsyncPageStorageService,
    exact: true
  },
  {
    path: ["/services/*"],
    component: AsyncPageServices,
    exact: true,
  },
  {
    path: ["/services",  "/services.html"],
    component: AsyncPageServices,
    exact: true,
  },
  
  {
    path: ["/contact", '/inquire'],
    exact: true,
    component: AsyncPageContact,
    loadData: () => {
      return {};
    }
  },
 
  {
    path: "/not-found",
    component: AsyncPageError,
    loadData: () => {
      return {};
    }
  },

  {
    path: "/disclaimer",
    component: AsyncPageDisclaimer,
    loadData: () => {
      return {};
    }
  },
  

  {
    path: "/dashboard",
    component: requireFacilitatorOrAdmin(AsyncAdminPanel),
    routes: [
      {
        path: "/dashboard/opportunities",
        component: requireFacilitatorOrAdmin(AsyncPageOpportunities),
        exact: true
      },
      {
        path: "/dashboard/listings",
        component: requireFacilitatorOrAdmin(AsyncPageListingsManagement),
        exact: true
      },
      
      {
        path: ["/dashboard/content-editor"],
        component: requireFacilitatorOrAdmin(AsyncPageBlogEditor),
        exact: true
      },
      {
        path: ["/dashboard/content-editor/:id"],
        component: requireFacilitatorOrAdmin(AsyncPageBlogEditor),
        exact: true
      },
      {
        component: requireFacilitatorOrAdmin(AsyncDashboard)
      }
    ]
  },
  {
    path: "/settings",
    component: requireAuth(AsyncSettingsPanel),
    routes: [
      {
        path: "/settings/account",
        component: AsyncEmailPreferences,
        exact: true
      },
    ]
  },
  {
    component: error404(AsyncPageError)
  }
];


export default Routes;
