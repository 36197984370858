import React, { useState, useEffect, useRef } from 'react';
import {NavLink as Link} from 'react-router-dom';
import { setGlobalSearchStatus } from 'state/application/actions';
import { trackClick } from 'utility/analytics';
import { useBranch } from 'baobab-react/hooks';
import {openModal} from 'state/modals/actions';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';

import { NavLink } from 'react-router-dom';
import MobileMenu from './components/MobileMenu/MobileMenu';
import {withRouter} from 'react-router-dom';
import ProductsDropdownMenu from 'components/NavigationDropdownMenus/ProductsDropdownMenu';
import ServicesDropdown from 'components/NavigationDropdownMenus/ServicesDropdownMenu';
import ContentDropdownMenu from 'components/NavigationDropdownMenus/ContentDropdownMenu';
import {
  PublicHeaderComponent,
  Menu,
  MenuItem,
  Logo,
  HeaderRight,
  MobileMenuTrigger,
} from './PublicHeader.styles.js';
 
const PublicHeader = (props) => {
  const alwaysWhiteMenu = props.match.path;
  const [whiteMenu, setWhiteMenu] = useState(false);
  const [mmActive, setmmActive] = useState(false);
  const scrollY = useRef();
  const [headerHidden, setHeaderHidden] = useState();
  const [mmScroll, setmmScroll] = useState(0);
  const [setSearchOverlay] = useState(false);
  const {searchOpen} = useBranch({
    searchOpen: ['application', 'searchStatus']
  });

  useEffect(() => {
    window.addEventListener('scroll', evalScroll)
    return () => {
      window.removeEventListener('scroll', evalScroll)
    };
  }, []);

  useEffect(() => {
    let timer = null;

    if (mmActive) {
      setmmScroll(window.scrollY);
      timer = setTimeout(function() {
        document.body.style.overflowY = 'hidden';
        document.documentElement.style.overflowY = 'hidden';
      }, 700)
    } else if (!mmActive) {
      document.body.style.overflowY = '';
      document.documentElement.style.overflowY = '';
      window.scrollTo(0, mmScroll);
    }

    return () => {
      clearTimeout(timer);
    }
  }, [mmActive])

  useEffect(() => {

    if (mmActive) {
      setmmActive(false);
    }
  }, [props.location])

  

  function toggleMM() {
    trackClick('toggleMobileMenu', 'hamburger', 'PublicHeader');
    setmmActive(!mmActive);
  }

  function evalScroll() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const scrollIsGreater = winScroll > scrollY.current;
    if (winScroll < 100) {
      setHeaderHidden(false)
    } else if (scrollIsGreater || winScroll <= 1200) {
      setHeaderHidden(true)
    } else if(!scrollIsGreater && winScroll > 1200) {
      setHeaderHidden(false)
    }
    scrollY.current = winScroll;
    if (winScroll > 10 && winScroll > 1200) {
      setWhiteMenu(true);
    } else {
      setWhiteMenu(false);
    }
    
  }

  
  function toggleSearch() {
    trackClick('toggleSearch', 'Search', 'PublicHeader');
    setSearchOverlay(!searchOpen);
    setGlobalSearchStatus(!searchOpen)
  }

  return (
    <PublicHeaderComponent 
      className="app-header-comp"
      alwaysWhiteMenu={alwaysWhiteMenu} 
      whiteMenu={(whiteMenu && !mmActive)}
      mmActive={mmActive}
      headerHidden={headerHidden}
    >
      <div className="header-inner">
        <Grid className="header-grid" >
          <Row className="header-row">
            <Col size="auto" className="logo-col">
              <NavLink exact className="nav-link-a" activeClassName="active-menu-item" to="/">
                <Logo className="animate-logo">
                  <img src="https://res.cloudinary.com/hbq7zoknx/image/upload/v1605799502/logo_wuopb5.svg" />
                </Logo>
              </NavLink>
            </Col>
            
            <Col size={4} xl={3} lg={3} style={{maxWidth: 'none', flex: 1}}  className="secondary-menu-col">
              <HeaderRight>
                <Menu className="animate-menu menu-1 menu">
                  <MenuItem>
                    <div className="inner">
                      <ServicesDropdown location={props.location}/>
                    </div>
                  </MenuItem>

                  <MenuItem>
                    <div className="inner">
                      <ProductsDropdownMenu />
                    </div>
                  </MenuItem>

                   <MenuItem>
                    <div className="inner">
                      <NavLink className="nav-link-a" to="/why-mrt">Why Us</NavLink>
                    </div>
                  </MenuItem>
                  
                  <MenuItem>
                    <div className="inner">
                      <NavLink className="nav-link-a" to="/company">Company</NavLink>
                    </div>
                  </MenuItem>

                   {/*<MenuItem>
                    <div className="inner">
                      <ContentDropdownMenu location={props.location}/>
                    </div>
                  </MenuItem>
*/}
                   <MenuItem>
                    <div className="inner">
                       <NavLink className="item" onClick={(e) => {
                        e.preventDefault()
                        openModal({
                          type: 'contactUsModal',
                        })
                       }} to="/contact">Contact Us</NavLink>
                    </div>
                  </MenuItem>
                </Menu>
              </HeaderRight>
            </Col>
          </Row>
        </Grid>
        { 
          searchOpen ?
          <div/> : null
        }
      </div>
      
      <MobileMenuTrigger onClick={() => toggleMM()} active={mmActive} whiteMenu={whiteMenu} className="mm-trigger">
        <span />
        <span />
        <span />
      </MobileMenuTrigger>
      <MobileMenu active={mmActive} />

      

    </PublicHeaderComponent>
  )
};


export default withRouter(PublicHeader);
